import React, { memo } from 'react';
import {
  CaretRightOutlined,
  PauseOutlined,
  StrikethroughOutlined,
  HighlightOutlined,
  UndoOutlined,
  RedoOutlined,
} from '@ant-design/icons';

import ExportModal from './ExportModal';
import './Toolbar.less';

interface Props {
  playing: boolean;
  playOrPause: () => void;
  strikethrough: (e: any) => void;
  highlight: (e: any) => void;
  undo: () => void;
  redo: () => void;
  savingDocument: boolean;
}
const Toolbar = (props: Props) => {
  const { playOrPause, playing, strikethrough, highlight, undo, redo, savingDocument } = props;

  return (
    <div className="toolbar-root">
      <div className="toolbar-left">
        <div className="toolbar-button" onClick={playOrPause} style={{ fontSize: 25 }}>
          {playing ? <PauseOutlined /> : <CaretRightOutlined />}
        </div>
        <div className="toolbar-divider" />
        <div className="toolbar-button" onMouseDown={strikethrough}>
          <StrikethroughOutlined />
        </div>
        <div className="toolbar-button" onMouseDown={highlight}>
          <HighlightOutlined />
        </div>
        <div className="toolbar-divider" />
        <div className="toolbar-button" onMouseDown={undo}>
          <UndoOutlined />
        </div>
        <div className="toolbar-button" onMouseDown={redo}>
          <RedoOutlined />
        </div>
      </div>
      <div className="toolbar-right">
        <ExportModal savingDocument={savingDocument} />
      </div>
    </div>
  );
};

export default memo(Toolbar);
