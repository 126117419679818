import { Node, Text, Editor, Transforms, Element, Range, Path, Point } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import uniq from 'lodash/uniq';

import { formatTimestamp } from '@/common/helper/time';

import { UNASSIGNED, MAX_SPEAKER_LENGTH } from './constants';
import { OutputSegment } from './helper';

export type TranscriptEditor = HistoryEditor &
  ReactEditor & {
    setSpeaker: (segmentId: number, speaker: string) => void;
    editAllSpeaker: (from: string, to: string) => void;
    clearSpeaker: () => void;
    strikethrough: () => void;
    highlight: (color?: string) => void;
    toggleFormat: (format: string) => void;
    isFormatActive: (format: string) => boolean;
    toggleComplete: (segmentId: number) => void;
    setNote: (segmentId: number, note: string) => void;
    editTimestamps: (segmentId: number, start: number, end: number) => void;
    getSpeakers: () => string[];

    forceSelectionSync: () => void;
    handlePunctuation: (e: any) => void;
    handleDelete: (e: any) => void;
    handleBackspace: (e: any) => void;
    insertSoftLineBreak: () => void;
    splitSegment: () => void;
    mergeSegments: (a, b) => void;
    mergeWords: (a, b) => void;
    handleExpandedSelection: (e: any) => void;
    ids: number;
    getNewId: () => number;
    getWordPoint: (segmentId: number, wordId: number) => Path | null;
    getSegmentId: (pos: number) => number;
    getWordId: (pos: number) => number;
    getSegments: () => OutputSegment[];
  };

export const withTranscript = (_editor: ReactEditor): TranscriptEditor => {
  const editor = _editor as TranscriptEditor;
  const { insertBreak } = editor;

  editor.ids = -1;

  editor.getNewId = () => {
    if (editor.ids < 0) {
      for (const segment of editor.children) {
        editor.ids = Math.max(editor.ids, (segment as any).id);
      }
    }
    return (editor.ids += 1);
  };

  editor.setSpeaker = (segmentId: number, speaker: string) => {
    for (let i = 0; i < editor.children.length; i++) {
      const segment = editor.children[i] as Element;
      if (segment.id === segmentId) {
        Transforms.setNodes(
          editor,
          {
            speaker_label: speaker.slice(0, MAX_SPEAKER_LENGTH),
          },
          { at: [i] }
        );
        break;
      }
    }
  };

  editor.editAllSpeaker = (from: string, to: string) => {
    for (let i = 0; i < editor.children.length; i++) {
      const segment = editor.children[i] as Element;
      if (segment.speaker_label === from) {
        Transforms.setNodes(
          editor,
          { speaker_label: to.slice(0, MAX_SPEAKER_LENGTH) },
          { at: [i] }
        );
      }
    }
  };

  editor.clearSpeaker = () => {
    for (let i = 0; i < editor.children.length; i++) {
      Transforms.setNodes(editor, { speaker_label: UNASSIGNED }, { at: [i] });
    }
  };

  editor.isFormatActive = (format: string) => {
    const [match] = Editor.nodes(editor, { match: (node) => node[format] === true });
    return !!match;
  };

  editor.toggleFormat = (format: string) => {
    const { selection } = editor;
    if (!selection) {
      return;
    }
    const isActive = editor.isFormatActive(format);

    Editor.withoutNormalizing(editor, () => {
      if (isActive) {
        Transforms.setNodes(
          editor,
          { [format]: null, startTimestamp: null, endTimestamp: null },
          {
            mode: 'highest',
            match: (node) => !!node.text && !!node.confidence,
          }
        );
      } else {
        Transforms.setNodes(
          editor,
          { [format]: true },
          {
            mode: 'highest',
            match: (node) => !!node.text && !!node.confidence,
          }
        );
      }
    });
  };

  editor.strikethrough = () => {
    editor.toggleFormat('strikethrough');
  };

  editor.highlight = () => {
    const backgroundColor = 'rgba(246, 230, 180, 0.533)';
    const color = 'rgb(166, 150, 100)';
    const isActive = editor.isFormatActive('highlight');
    Editor.withoutNormalizing(editor, () => {
      editor.toggleFormat('highlight');
      Transforms.setNodes(
        editor,
        {
          highlightColor: isActive ? null : color,
          highlightBackground: isActive ? null : backgroundColor,
        },
        {
          mode: 'highest',
          match: (node) => !!node.text && !!node.confidence,
        }
      );
    });
  };

  editor.toggleComplete = (segmentId: number) => {
    const [match] = Editor.nodes(editor, {
      at: [],
      match: (node) => node.type === 'segment' && node.id === segmentId,
    });
    if (!match) {
      return;
    }
    const [node, path] = match;
    const isComplete = node.complete;
    Transforms.setNodes(editor, { complete: isComplete ? null : true }, { at: path });
  };

  editor.setNote = (segmentId: number, note: string) => {
    const [match] = Editor.nodes(editor, {
      at: [],
      match: (node) => node.type === 'segment' && node.id === segmentId,
    });
    if (!match) {
      return;
    }
    const [, path] = match;
    Transforms.setNodes(editor, { note: note ? note : null }, { at: path });
  };

  editor.editTimestamps = (segmentId: number, start: number, end: number) => {
    const [match] = Editor.nodes(editor, {
      at: [],
      match: (node) => node.type === 'segment' && node.id === segmentId,
    });
    if (!match) {
      return;
    }
    const [node, path] = match;
    if (node.start_time === start && node.end_time === end) {
      return;
    }
    Transforms.setNodes(editor, { start_time: start, end_time: end }, { at: path });
  };

  editor.getSpeakers = () => {
    const speakers = uniq(editor.children.map((s) => s.speaker_label as string)).sort();
    if (speakers.includes(UNASSIGNED)) {
      return speakers.filter((s) => s !== UNASSIGNED).concat([UNASSIGNED]);
    }
    return speakers;
  };

  editor.getSegments = () => {
    return editor.children as OutputSegment[];
  };

  editor.insertBreak = () => {
    insertBreak();
    if (editor.selection) {
      const e = Range.start(editor.selection);
      Transforms.setNodes(
        editor,
        {
          id: editor.getNewId(),
          note: null,
        },
        {
          at: [e.path[0]],
        }
      );
    }
  };

  editor.forceSelectionSync = function () {
    const t = window.getSelection();
    const n = t && t.rangeCount > 0 && t.getRangeAt(0);
    if (n) {
      const r = ReactEditor.toSlateRange(editor, n);
      Transforms.select(editor, r);
    }
  };

  editor.handlePunctuation = (e: any) => {};

  editor.handleDelete = (e: any) => {
    editor.forceSelectionSync();
    const start = Range.start(editor.selection!);
    const segmentPath = start.path.slice(0, 1);
    const isSegmentBegin = 0 === start.path[1];
    if (Point.equals(start, Editor.end(editor, segmentPath))) {
      // If the cursor is at the end of a segment, merge the segment with the next one
      if (start.path[0] === editor.children.length - 1) return;
      e.preventDefault();
      const o = Editor.next(editor, {
        at: segmentPath,
      })![1];
      editor.mergeSegments(segmentPath, o);
    } else if (!isSegmentBegin && Editor.isStart(editor, start, start.path)) {
      // A word is normally padded with a space in front (except for the first word of a segment)
      // So this part check if the cursor is before the padding space, if so, delete the space and merge the word
      // with the previous one
      const i = Editor.previous(editor, {
        at: start.path,
      })![1];
      e.preventDefault();
      editor.deleteForward('character');
      editor.mergeWords(i, start.path);
    } else if (Editor.isEnd(editor, start, start.path)) {
      // If the cursor is instead at the end of a word, merge the word with the next word
      const s = Editor.next(editor, {
        at: start.path,
      })![1];
      e.preventDefault();
      editor.deleteForward('character');
      editor.mergeWords(start.path, s);
    }
  };

  editor.handleBackspace = (e: any) => {
    editor.forceSelectionSync();
    const start = Range.start(editor.selection!);
    const segmentPath = start.path.slice(0, 1);
    const isSegmentBegin = 0 === start.path[1];
    const node = Node.get(editor, start.path) as Text;
    if (Point.equals(start, Editor.start(editor, segmentPath)) && 0 !== segmentPath[0]) {
      // If the cursor is at the beginning of the segment, merge it with the previous one
      e.preventDefault();
      const i = Editor.previous(editor, { at: segmentPath })![1];
      editor.mergeSegments(i, segmentPath);
    } else if (!isSegmentBegin && 1 === start.offset && ' ' === node.text[0]) {
      // Merge words otherwise
      const s = Editor.previous(editor, { at: start.path })![1];
      e.preventDefault();
      editor.deleteBackward('character');
      if (node.text.trim().length > 0) {
        editor.mergeWords(s, start.path);
      }
    }
  };

  editor.mergeSegments = (a, b) => {
    const nodeA = Node.get(editor, a) as any;
    const nodeB = Node.get(editor, b) as any;
    Editor.withoutNormalizing(editor, () => {
      Transforms.mergeNodes(editor, { at: b });
      // Update segment end_time and note only
      const options: any = { end_time: nodeB.end_time };
      if (nodeB.note) {
        options.note = nodeA.note ? nodeA.note + '\n\n' + nodeB.note : nodeB.note;
      }
      Transforms.setNodes(editor, options, { at: a });
      for (let i = 0; i < nodeB.children.length; i++) {
        Transforms.setNodes(
          editor,
          { id: nodeA.children.length + i },
          { at: [...a, nodeA.children.length + i] }
        );
      }
    });
  };

  editor.mergeWords = (a, b) => {
    const node = Node.get(editor, b);
    Transforms.mergeNodes(editor, {
      at: b,
    });
    // Update word end_time only
    Transforms.setNodes(editor, { end_time: node.end_time }, { at: a });
  };

  editor.insertSoftLineBreak = () => {
    Transforms.insertText(editor, '\n');
  };

  editor.splitSegment = () => {
    if (!editor.selection) {
      return;
    }
    editor.forceSelectionSync();
    const start = Range.start(editor.selection);
    const segmentPath = start.path.slice(0, 1);
    const newSegmentPath = [segmentPath[0] + 1];
    if (
      !Point.equals(start, Editor.start(editor, segmentPath)) &&
      !Point.equals(start, Editor.end(editor, segmentPath))
    ) {
      // If the cursor is not at the beginning or the end of the document, we split the current node
      Transforms.splitNodes(editor, {
        always: true,
      });
      const currentSegment = Node.get(editor, segmentPath) as any;
      const newSegment = Node.get(editor, newSegmentPath) as any;
      const lastNodeEntry = Node.last(editor, segmentPath);
      const lastNode = lastNodeEntry[0] as Text;
      const lastNodePath = lastNodeEntry[1];
      if ('' === lastNode.text.trim()) {
        // If after split, the last word of the current segment is empty, remove it
        Transforms.removeNodes(editor, { at: lastNodePath });
      } else {
        // Another case is the first word of the new segment has leading spaces, remove those spaces
        const first = Node.first(editor, newSegmentPath)[0] as Text;
        const newText = first.text.replace(/^\s+/g, '');
        if (newText.length !== first.text.length) {
          const diff = first.text.length - newText.length;
          Transforms.delete(editor, {
            at: {
              anchor: { path: [...newSegmentPath, 0], offset: 0 },
              focus: { path: [...newSegmentPath, 0], offset: diff },
            },
          });
        }
      }
      // Update the end_time of the currentSegment
      let currentEnd = Math.min(
        currentSegment.children[currentSegment.children.length - 1].end_time,
        currentSegment.end_time
      );
      if ('' === lastNode.text.trim() && currentSegment.children.length > 1) {
        // Account for the case when the last word is empty
        currentEnd = Math.min(
          currentEnd,
          currentSegment.children[currentSegment.children.length - 2].end_time
        );
      }
      Transforms.setNodes(editor, { end_time: currentEnd }, { at: segmentPath });

      // Update the start_time of the newSegment
      const newStart = Math.max(newSegment.children[0].start_time, newSegment.start_time);
      Transforms.setNodes(
        editor,
        {
          start_time: newStart,
          note: null,
          id: editor.getNewId(),
          timestamp: formatTimestamp(newStart.toString(), true),
        },
        { at: newSegmentPath }
      );
    }
  };

  editor.handleExpandedSelection = (e: any) => {
    const start = Range.start(editor.selection!);
    const end = Range.end(editor.selection!);

    if (start.path[0] !== end.path[0]) {
      e.preventDefault();
    }
  };

  editor.getWordPoint = (segmentId: number, wordId: number) => {
    for (let i = 0; i < editor.children.length; i++) {
      const segment = editor.children[i] as Element;
      if (segment.id === segmentId) {
        for (let j = 0; j < segment.children.length; j++) {
          const node = segment.children[j];
          if (node.id === wordId) {
            return [i, j];
          }
        }
      }
    }
    return null;
  };

  editor.getSegmentId = (pos: number) => {
    const s = editor.children.find(
      (segment: any) => segment.start_time <= pos && pos < segment.end_time
    );
    return s ? (s.id as number) : -1;
  };

  editor.getWordId = (pos: number) => {
    const s: any = editor.children.find(
      (segment: any) => segment.start_time <= pos && pos < segment.end_time
    );
    if (!s) {
      return -1;
    }
    const w = s.children.find((word: any) => word.start_time <= pos && pos < word.end_time);
    return w ? (w.id as number) : -1;
  };

  return editor;
};
