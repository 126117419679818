import { ID_TOKEN_KEY } from '@/common/provider/auth';

export const getMediaDuration = (file: File | Blob): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadedmetadata = function () {
      resolve(Math.round(video.duration));
      URL.revokeObjectURL(video.src);
      video.remove();
    };
    video.onerror = function (error) {
      reject(error);
      video.remove();
    };
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
  });
};

export const getPresignedUrl = async (
  fileName: string,
  uuid: string,
  length: number,
  parentId?: number
): Promise<string> => {
  const token = localStorage.getItem(ID_TOKEN_KEY);
  if (!token) {
    return Promise.reject('Unauthorized');
  }
  const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/files/sign`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      uuid,
      filename: fileName,
      length,
      parentId,
    }),
  });
  const json = await res.json();
  return json.data;
};
