import React, { useCallback } from 'react';
import { Modal, Form, Input } from 'antd';
import { useIntl } from 'react-intl';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (name: string) => Promise<any>;
}
const NewFolderModal = (props: Props) => {
  const { visible, onClose, onSubmit } = props;

  const intl = useIntl();
  const [form] = Form.useForm();

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      onSubmit(values.name).then(() => {
        onClose();
        form.resetFields();
      });
    },
    [form, onClose, onSubmit]
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({
        description: 'New folder form modal title',
        defaultMessage: 'New folder',
      })}
      okText={intl.formatMessage({
        description: 'Create button label',
        defaultMessage: 'Create',
      })}
      cancelText={intl.formatMessage({
        description: 'Cancel button label',
        defaultMessage: 'Cancel',
      })}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label={intl.formatMessage({
            description: 'New folder form label: folder name',
            defaultMessage: 'Folder name',
          })}
          rules={[{ required: true }]}
        >
          <Input autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewFolderModal;
