/* istanbul ignore file */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AccountProvider } from '@/common/provider/account';
import { AuthProvider } from '@/common/provider/auth';
import { FirebaseAppProvider } from '@/common/provider/firebase';

import AppSumo from '../AppSumo';
import Login from '../Login/Login';
import SignedIn from '../Login/SignedIn';

import ErrorBoundary from './component/ErrorBoundary';

import AppContainer from './AppContainer';

import { theme } from './theme';
import GraphqlProvider from '@/common/provider/graphql';

export default () => {
  return (
    <FirebaseAppProvider>
      <GraphqlProvider>
        <AuthProvider>
          <AccountProvider>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Switch>
                  <Route path="/appsumo/:coupon" component={AppSumo} />
                  <Route path="/appsumo" component={AppSumo} />
                  <Route path="/login" component={Login} />
                  <Route path="/signed-in" component={SignedIn} />
                  <Route path="/" component={AppContainer} />
                </Switch>
              </ErrorBoundary>
            </ThemeProvider>
          </AccountProvider>
        </AuthProvider>
      </GraphqlProvider>
    </FirebaseAppProvider>
  );
};
