import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import isNaN from 'lodash/isNaN';

import Top from './Top';

import './Home.less';

const Home = () => {
  const params = useParams<{ folderId?: string }>();

  const folderId = useMemo(() => {
    return params.folderId ? parseInt(params.folderId, 10) : undefined;
  }, [params]);

  if (isNaN(folderId)) {
    // TODO:
    return <div>Error</div>;
  }

  return <Top folderId={folderId} />;
};

export default Home;
