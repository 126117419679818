import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography, Row, Col, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';

import { useDebounce } from '@/common/hook/debounce';

import {
  useGetPayLinkLazyQuery,
  useGetPriceLazyQuery,
  useCreatePurchaseMutation,
} from '@/graphql/generated/hooks';

import PriceTable from './component/PriceTable';

import './Purchase.less';

const Purchase = () => {
  const history = useHistory();

  const [getPayLink, { data: payLinkData, loading: payLinkLoading }] = useGetPayLinkLazyQuery();
  const [getPrice, { data: priceData, loading: priceLoading }] = useGetPriceLazyQuery();
  const [createPurchase] = useCreatePurchaseMutation();

  const [hours, setHours] = useState<number | null>(null);
  const [pid] = useState<string>(() => uuidv4());
  const debouncedHours = useDebounce(hours, 300);

  const handleCheckout = useCallback(() => {
    if (hours && Number(hours) > 0) {
      getPayLink({
        variables: {
          hours: parseInt(hours.toString(), 10),
          pid,
        },
      });
    }
  }, [getPayLink, hours, pid]);

  const triggerCheckout = useCallback(
    (url) => {
      function checkoutComplete(data) {
        if (hours) {
          createPurchase({
            variables: {
              hours: parseInt(hours.toString(), 10),
              pid,
            },
          })
            .then(() => {
              history.push('/');
            })
            .catch((err) => {
              // TODO: Notify user
              Sentry.captureException(err);
            });
        }
      }

      (window as any).Paddle.Checkout.open({
        override: url,
        successCallback: checkoutComplete,
      });
    },
    [history, createPurchase, hours, pid]
  );

  useEffect(() => {
    if (!(window as any).Paddle) {
      const script = document.createElement('script');
      script.src = 'https://cdn.paddle.com/paddle/paddle.js';
      script.onload = function initPaddle() {
        (window as any).Paddle.Setup({
          vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || '', 10),
        });
      };
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (payLinkData) {
      triggerCheckout(payLinkData.payLink);
    }
  }, [payLinkData, triggerCheckout]);

  useEffect(() => {
    if (debouncedHours) {
      getPrice({ variables: { hours: debouncedHours } });
    }
  }, [getPrice, debouncedHours]);

  return (
    <div className="purchase-root">
      <Typography.Title level={3}>Buy more time</Typography.Title>
      <Row>
        <Col xs={24} md={12}>
          <div className="price-guide-container">
            <PriceTable />
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="checkout-container">
            <Typography.Title level={4}>Enter hours to buy</Typography.Title>

            <div className="inputs">
              <InputNumber
                placeholder="Hours"
                max={100}
                min={0}
                step={1}
                value={hours ? parseInt(hours.toString(), 10) : undefined}
                onChange={(e) => setHours(e)}
              />
              <div className="price">
                ${hours ? (priceLoading || !priceData ? null : priceData.price) : null}
              </div>
            </div>

            <Button onClick={handleCheckout} disabled={!hours} loading={payLinkLoading}>
              Continue to checkout
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Purchase;
