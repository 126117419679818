import React, { useCallback, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { NetworkStatus } from '@apollo/client';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { FileContentFragmentFragment } from '@/graphql/generated/operations';
import { useGetRecentFilesQuery } from '@/graphql/generated/hooks';

import FileTable from '@/common/component/FileTable';

import './Recent.less';

const TAKE = 25;

const Recent = () => {
  const { error, data, fetchMore, networkStatus, startPolling, stopPolling } =
    useGetRecentFilesQuery({
      variables: { skip: 0, take: TAKE },
      notifyOnNetworkStatusChange: true,
    });

  const handleLoadMore = useCallback(() => {
    return fetchMore({
      variables: {
        skip: data?.recentFiles.items.length,
        take: TAKE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({}, prev, {
          recentFiles: {
            items: [...prev.recentFiles.items, ...fetchMoreResult.recentFiles.items],
            total: fetchMoreResult.recentFiles.total,
          },
        });
      },
    });
  }, [data, fetchMore]);

  useEffect(() => {
    startPolling(5000);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  // TODO: Handle error
  if (error) {
    return <div>{`Error! ${error}`}</div>;
  }

  return (
    <div className="home-root">
      <div className="recent-top-section">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/">
              <FieldTimeOutlined />
              &nbsp;{' '}
              <FormattedMessage description="Recent breadcrumb text" defaultMessage="Recent" />
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <FileTable<FileContentFragmentFragment>
        data={data?.recentFiles?.items ?? []}
        total={data?.recentFiles?.total ?? 0}
        loading={networkStatus === NetworkStatus.loading}
        loadMore={handleLoadMore}
        loadingMore={networkStatus === NetworkStatus.fetchMore}
      />
    </div>
  );
};

export default React.memo(Recent);
