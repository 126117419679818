import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Layout } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { useGA, usePageView } from '@/common/hook/ga';
import { useAuthState } from '@/common/provider/auth';
import { useFirebaseApp } from '@/common/provider/firebase';

import GlobalHeader from '@/common/component/GlobalHeader';
import Footer from '@/common/component/Footer';
import SideMenu from '@/common/component/SideMenu';

import Routes from './Routes';

const AuthorizedApp = () => {
  // Hide sidebar when route matches editor
  const matchEditor = useRouteMatch('/t/:fileId');

  return (
    <Layout>
      {!matchEditor?.isExact && <SideMenu />}

      <Layout>
        <Layout.Header
          style={{
            height: 48,
            background: 'transparent',
            lineHeight: '48px',
          }}
        />

        <Layout.Header
          style={{
            right: 0,
            padding: 0,
            height: 48,
            width: '100%',
            lineHeight: '48px',
          }}
          className="fixed-header"
        >
          <GlobalHeader />
        </Layout.Header>
        <Layout.Content>
          <Routes />
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>

      <ToastContainer autoClose={3000} closeOnClick />
    </Layout>
  );
};

const AppContainer = () => {
  const { user } = useAuthState();
  const fbApp = useFirebaseApp();

  useGA();

  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user.uid });
    }
  }, [user]);

  useEffect(() => {
    if (user && fbApp) {
      fbApp.analytics().setUserId(user.uid);
      fbApp.analytics().setUserProperties({ email: user.email });
    }
  }, [user, fbApp]);

  usePageView();

  if (!user) {
    return null;
  }

  return <AuthorizedApp />;
};

export default AppContainer;
