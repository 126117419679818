/* istanbul ignore file */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { I18nProvider } from './common/provider/i18n';

import App from './app/App';

import * as serviceWorker from './serviceWorker';

import './global.less';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://bd813dc6aef84f2da69ebdc9a4ac7c49@o469412.ingest.sentry.io/5498845',
    integrations: [Sentry.reactRouterV6BrowserTracingIntegration],
    environment: process.env.NODE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <I18nProvider>
      <App />
    </I18nProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
