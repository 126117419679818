import React, { useCallback } from 'react';
import { Button, Typography, Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';

import { useLifetime } from '../hook/lifetime';

import './PlanView.less';

const StandardPlanView = () => {
  const history = useHistory();

  const checkoutSuccessCallback = useCallback(() => {
    history.push('/');
  }, [history]);

  const { handleCheckout } = useLifetime({ checkoutSuccessCallback });

  return (
    <div className="package-list-root">
      <Row>
        <Col xs={24}>
          <Typography.Title level={3}>Select a plan</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Standard</Typography.Title>
            <div className="subtext">Pay-as-you-go</div>

            <Typography.Paragraph className="row description">
              Pay-as-you-go transcription. Perfect for project-based work.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              $ {process.env.REACT_APP_STANDARD_HOUR_PRICE} / HOUR
            </Typography.Paragraph>

            <div className="row">
              <Link to="/purchase">
                <Button>
                  <ShoppingCartOutlined /> Buy standard
                </Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Premium</Typography.Title>
            <div className="subtext">Subscription</div>

            <Typography.Paragraph className="row description">
              More frequent transcription needs with better pricing.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              $ {process.env.REACT_APP_PREMIUM_HOUR_PRICE} / HOUR
              <br />
              PLUS $ {process.env.REACT_APP_MONTHLY_PRICE} / MONTH
              <br /> AND {process.env.REACT_APP_MONTHLY_HOUR_ALLOWANCE} FREE HOURS / MONTH
            </Typography.Paragraph>

            <div className="row">
              <Link to="/subscribe">
                <Button>
                  <ShoppingCartOutlined /> Buy premium
                </Button>
              </Link>
            </div>
          </div>
        </Col>
        {/*
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Enterprise</Typography.Title>
            <div className="subtext">Subscription</div>

            <Typography.Paragraph className="row description">
              High volume transcription with more specific needs.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              PLEASE CONTACT OUR TEAM
            </Typography.Paragraph>

            <div className="row">
              <a href="mailto:ellen@izitext.io">
                <Button>
                  <MailOutlined /> Contact us
                </Button>
              </a>
            </div>
          </div>
        </Col>
        */}
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Lifetime</Typography.Title>
            <div className="subtext">Pay once and use for a lifetime</div>

            <Typography.Paragraph className="row description">
              Enjoy a better hour pricing for a lifetime.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              $ {process.env.REACT_APP_LIFETIME_PRICE} once
              <br />
              FOR {process.env.REACT_APP_LIFETIME_ALLOWANCE} FREE HOURS (once)
              <br />
              AND $ {process.env.REACT_APP_LIFETIME_HOUR_PRICE} / HOUR forever
            </Typography.Paragraph>

            <div className="row">
              <Button
                onClick={() => {
                  handleCheckout();
                }}
              >
                <ShoppingCartOutlined /> Buy lifetime
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StandardPlanView;
