import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useAuthState } from '@/common/provider/auth';

import { useCreateAccountIfNotExistMutation } from '@/graphql/generated/hooks';

const SignedIn = () => {
  const history = useHistory();
  const { user, authenticating } = useAuthState();
  const [createAccountIfNotExist] = useCreateAccountIfNotExistMutation();

  useEffect(() => {
    if (!authenticating && user) {
      createAccountIfNotExist()
        .then(() => {
          history.push('/');
        })
        .catch((e) => {
          // TODO: Deal with error
          Sentry.captureException(e);
          history.push('/login');
        });
    }
  }, [user, authenticating, history, createAccountIfNotExist]);

  if (!user && !authenticating) {
    return <Redirect to="/login" />;
  }

  return null;
};

export default SignedIn;
