import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory, Link, Redirect } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { DragDrop } from '@uppy/react';
import qs from 'qs';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { ALLOWED_FILE_TYPES, ALLOWED_MIME_TYPES } from '@/common/constant/upload';
import { getPresignedUrl } from '@/common/helper/file';

import { useGetFileLazyQuery, useUploadAndTranscribeMutation } from '@/graphql/generated/hooks';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

import UploadProgress from './component/UploadProgress';

import './UploadScreen.less';

const maxFileSize = process.env.REACT_APP_UPLOAD_SIZE_LIMIT
  ? parseInt(process.env.REACT_APP_UPLOAD_SIZE_LIMIT, 10)
  : undefined;

const UploadScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { f } = qs.parse(location.search.slice(1));

  const [getFile, { data: fileData }] = useGetFileLazyQuery();
  const [uploadAndTranscribe, { loading: uploadAndTranscribing }] =
    useUploadAndTranscribeMutation();

  const handleSubmit = (files: { uuid: string; language: string; maxSpeakerCount: number }[]) => {
    uploadAndTranscribe({
      variables: { files },
    })
      .then(() => {
        if (fileData && fileData.file) {
          history.push(`/f/${fileData.file.id}`);
        } else {
          history.push('/');
        }
        toast.success('Files uploaded successfully');
      })
      .catch((err) => {
        toast.error(err.message);
        Sentry.captureException(err);
      });
  };

  const uppy = useMemo(() => {
    const _uppy = Uppy<Uppy.StrictTypes>({
      autoProceed: false,
      allowMultipleUploads: true,
      restrictions: {
        allowedFileTypes: ALLOWED_FILE_TYPES,
      },
      onBeforeFileAdded: (currentFile) => {
        if (maxFileSize && currentFile.data.size > maxFileSize) {
          _uppy.info('File size exceeded', 'error');
          return false;
        }
        if (currentFile.type && !ALLOWED_MIME_TYPES.includes(currentFile.type)) {
          _uppy.info('File type not supported', 'error');
          return false;
        }
        (currentFile.meta as any).uuid = uuidv4();
        return currentFile;
      },
      meta: f ? { parentId: f } : {},
    }).use(AwsS3, {
      getUploadParameters: (file) => {
        const meta = file.meta as any;
        return getPresignedUrl(file.name, meta.uuid, meta.length, meta.parentId).then((url) => {
          return {
            method: 'put',
            url,
            fields: {},
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          };
        });
      },
    });

    _uppy.on('info-visible', () => {
      const info = _uppy.getState().info;

      if (info) {
        toast(info.message, {
          type: info.type as any,
        });
      }
    });

    return _uppy;
  }, [f]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy, f]);

  useEffect(() => {
    if (f) {
      getFile({ variables: { id: parseInt(f, 10) } });
    }
  }, [f, getFile]);

  if (fileData && fileData.file && !fileData.file.isFolder) {
    // TODO: Maybe display error
    return <Redirect to="/" />;
  }

  return (
    <div className="upload-root">
      <Row style={{ marginBottom: 16 }}>
        <Col xs={24}>
          {f ? (
            fileData &&
            fileData.file && (
              <Typography.Title level={3}>
                Upload to <Link to={`/f/${fileData.file.id}`}>{fileData.file.name}</Link>
              </Typography.Title>
            )
          ) : (
            <Typography.Title level={3}>
              Upload to <Link to="/">Top</Link>
            </Typography.Title>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={6} className="upload-dnd-container">
          <DragDrop
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: 'Drop here or %{browse}',
                browse: 'browse',
              },
            }}
          />

          <p className="upload-note">
            Allowed file types are {ALLOWED_FILE_TYPES.join(', ')}
            <br />
            Maximum audio file length:{' '}
            {parseFloat(process.env.REACT_APP_UPLOAD_DURATION_LIMIT || '0') / 3600} hours
            <br />
            Maximum audio file size:{' '}
            {parseFloat(process.env.REACT_APP_UPLOAD_SIZE_LIMIT || '0') / 1000 / 1000 / 1000} GB
          </p>
        </Col>

        <Col xs={24} xl={18}>
          <UploadProgress uppy={uppy} onSubmit={handleSubmit} submitting={uploadAndTranscribing} />
        </Col>
      </Row>
    </div>
  );
};

export default UploadScreen;
