export const CONFIDENCE_THRESHOLD = {
  VERY: 0.95,
  FAIRLY: 0.85,
};

export const OVERALL_CONFIDENCE_THRESHOLD = {
  FEW: 0.9,
  MODERATE: 0.7,
};

export const UNASSIGNED = '(unassigned)';

export const MAX_SPEAKER_LENGTH = 30;
