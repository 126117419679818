import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'antd';
import { CloseCircleOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import uniq from 'lodash/uniq';

import { useOutsideClick } from '@/common/hook/outside-click';

import { EditorContext } from '../EditorContainer';
import { UNASSIGNED } from '../constants';

import './SpeakerChooser.less';

interface Props {
  currentSpeaker: string;
  element: any;
}
const SpeakerChooser = (props: Props) => {
  const { currentSpeaker, element } = props;

  const editor = useContext(EditorContext);

  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState<number[]>([]);
  const [speakers, setSpeakers] = useState<string[]>([]);

  const currentRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const chooseSpeaker = useCallback(
    (speaker: string) => {
      editor.setSpeaker(element.id, speaker);
    },
    [editor, element]
  );

  const editSpeaker = useCallback(
    (oldSpeaker: string, newSpeaker: string) => {
      editor.editAllSpeaker(oldSpeaker, newSpeaker);
    },
    [editor]
  );

  const handleClickOutside = useCallback((event) => {
    if (currentRef.current && currentRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, []);

  const handleClickCurrent = useCallback(() => setOpen((o) => !o), []);

  const handleRemove = useCallback(() => {
    chooseSpeaker(UNASSIGNED);
    setOpen(false);
  }, [chooseSpeaker]);

  const handleChooseSpeaker = useCallback(
    (speaker: string) => {
      chooseSpeaker(speaker);
      setOpen(false);
      setEditing([]);
    },
    [chooseSpeaker]
  );

  const handleEditSpeaker = useCallback(
    (from: string, to: string) => {
      if (!to || to.length === 0) {
        return;
      }
      editSpeaker(from, to);
      setOpen(false);
      setEditing([]);
    },
    [editSpeaker]
  );

  const handleStartEdit = useCallback((index: number) => {
    setEditing((editing) => uniq(editing.concat([index])));
  }, []);

  const dropdownHeight = useMemo(() => {
    return speakers.includes(UNASSIGNED) ? speakers.length * 40 : (speakers.length + 1) * 40;
  }, [speakers]);

  useOutsideClick(dropdownRef, handleClickOutside);

  useEffect(() => {
    if (open) {
      setSpeakers(editor.getSpeakers());
    }
  }, [open, editor]);

  return (
    <div className="speaker-chooser-root">
      <div
        className={clsx({
          'speaker-chooser-current': true,
          active: open,
        })}
        onClick={handleClickCurrent}
        ref={currentRef}
      >
        <UserOutlined /> <span className="speaker-chooser-current-text">{currentSpeaker}</span>
      </div>
      <div
        className="speaker-chooser-dropdown"
        style={open ? { height: dropdownHeight } : { height: 0, border: 'none' }}
        ref={dropdownRef}
      >
        <ul>
          {speakers.map((speaker, idx) => {
            if (speaker === UNASSIGNED) {
              return null;
            }
            return (
              <li key={speaker}>
                {editing.includes(idx) ? (
                  <Input.Search
                    style={{ width: '100%' }}
                    placeholder="Input new speaker"
                    enterButton="Save"
                    bordered={false}
                    maxLength={30}
                    size="large"
                    defaultValue={speaker}
                    autoFocus
                    onSearch={(value) => handleEditSpeaker(speaker, value)}
                  />
                ) : (
                  <>
                    <span
                      className="speaker-chooser-item-icon"
                      onClick={() => handleStartEdit(idx)}
                    >
                      <EditOutlined />
                    </span>
                    <span
                      className="speaker-chooser-item-value"
                      onClick={() => handleChooseSpeaker(speaker)}
                    >
                      {speaker}
                    </span>
                  </>
                )}
              </li>
            );
          })}

          <li>
            {editing.includes(-1) ? (
              <Input.Search
                style={{ width: '100%' }}
                placeholder="Input new speaker"
                enterButton="Save"
                bordered={false}
                size="large"
                autoFocus
                onSearch={handleChooseSpeaker}
              />
            ) : (
              <>
                <span className="speaker-chooser-item-icon" onClick={handleRemove}>
                  <CloseCircleOutlined />
                </span>
                <span className="speaker-chooser-item-value" onClick={() => handleStartEdit(-1)}>
                  Add new speaker
                </span>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpeakerChooser;
