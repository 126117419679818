import React, { useCallback, useContext, useState, useMemo, useEffect, memo } from 'react';
import { Button, Table } from 'antd';
import sum from 'lodash/sum';

import { formatTimestamp } from '@/common/helper/time';

import { UNASSIGNED } from '../constants';
import { OutputSegment } from '../helper';
import { EditorContext } from '../EditorContainer';

import './SpeakerStats.less';

const countSegmentWords = (segment: OutputSegment) => {
  return sum(
    segment.children.map(
      (child) =>
        child.text
          .split(' ')
          .map((part) => part.trim())
          .filter((part) => !!part).length
    )
  );
};

interface Props {
  open: boolean;
}
const SpeakerStats = (props: Props) => {
  const { open } = props;

  const editor = useContext(EditorContext);

  const [speakerList, setSpeakerList] = useState<string[]>([]);
  const [segments, setSegments] = useState<OutputSegment[]>([]);

  const handleClearSpeaker = useCallback(() => {
    editor.clearSpeaker();
    setSpeakerList([]);
    setSegments(editor.getSegments());
  }, [editor]);

  const handleLabelRemaining = useCallback(
    (speaker: string) => {
      editor.editAllSpeaker(UNASSIGNED, speaker);
      setSegments(editor.getSegments());
    },
    [editor]
  );

  const speakers = useMemo(() => {
    if (speakerList.includes(UNASSIGNED)) {
      return speakerList;
    }
    return speakerList.concat([UNASSIGNED]);
  }, [speakerList]);

  const totalWordCount = useMemo(() => {
    return segments.map(countSegmentWords).reduce((sum, segment) => sum + segment, 0);
  }, [segments]);

  const stats = useMemo(() => {
    const results = {};
    for (const speaker of speakers) {
      const wordCount = segments
        .filter((s) => s.speaker_label === speaker)
        .map(countSegmentWords)
        .reduce((sum, segment) => sum + segment, 0);
      const length = segments
        .filter((s) => s.speaker_label === speaker)
        .reduce((sum, segment) => sum + segment.end_time - segment.start_time, 0);
      results[speaker] = {
        wordCount,
        percentage: ((wordCount / totalWordCount) * 100).toFixed(2),
        length,
      };
    }
    return results;
  }, [segments, speakers, totalWordCount]);

  const dataSource = useMemo(() => {
    return speakers.map((speaker, index) => {
      const s = stats[speaker];
      return {
        key: index + 1,
        name: speaker,
        stats: `${formatTimestamp(s.length)} | ${s.percentage}% | ${s.wordCount} words`,
        remaining: stats[UNASSIGNED].wordCount,
      };
    });
  }, [speakers, stats]);

  const columns = useMemo(() => {
    return [
      {
        title: '',
        key: 'color',
        render: (_, record) => <div className={`block-color speaker-${record.key}`} />,
      },
      {
        title: 'Speaker',
        key: 'speaker',
        render: (_, record) => (
          <div className={`info speaker-${record.key}`}>
            <strong>{record.name}</strong>
            <br />
            {record.stats}
          </div>
        ),
      },
      {
        title: 'Bulk actions',
        key: 'actions',
        render: (_, record) => (
          <div className={`actions`}>
            {record.name === UNASSIGNED ? (
              <Button block danger onClick={handleClearSpeaker}>
                Clear all labels
              </Button>
            ) : (
              <Button
                block
                onClick={() => handleLabelRemaining(record.name)}
                disabled={record.remaining === 0}
              >
                Label remaining
              </Button>
            )}
          </div>
        ),
      },
    ];
  }, [handleClearSpeaker, handleLabelRemaining]);

  useEffect(() => {
    if (open) {
      setSpeakerList(editor.getSpeakers());
      setSegments(editor.getSegments());
    }
  }, [open, editor]);

  if (totalWordCount === 0) {
    // TODO:
    return null;
  }

  return (
    <div className="speaker-stats-root">
      <div className="speaker-stats-chart">
        {speakers.map((s, index) => (
          <div
            className={`speaker-stats-chart-portion speaker-${index + 1}`}
            style={{ width: `${stats[s].percentage}%` }}
            key={index}
          />
        ))}
      </div>

      <div className="speaker-stats-details-table">
        <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
      </div>
    </div>
  );
};

export default memo(SpeakerStats);
