/* istanbul ignore file */

import React from 'react';

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

type FirebaseAppContextValue = firebase.app.App;

const DEFAULT_APP_NAME = '[DEFAULT]';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const FirebaseAppContext = React.createContext<FirebaseAppContextValue | undefined>(undefined);

const FirebaseAppProvider = (props: { [key: string]: unknown }) => {
  const firebaseApp: firebase.app.App = React.useMemo(() => {
    const existingApp = firebase.apps.find((app) => app.name === DEFAULT_APP_NAME);
    if (existingApp) {
      return existingApp;
    }
    const fbApp = firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    return fbApp;
  }, []);

  return <FirebaseAppContext.Provider value={firebaseApp} {...props} />;
};

const useFirebaseApp = () => {
  const firebaseApp = React.useContext(FirebaseAppContext);
  if (!firebaseApp) {
    throw new Error(
      'Cannot call useFirebaseApp unless your component is within a FirebaseAppProvider'
    );
  }

  return firebaseApp;
};

export { FirebaseAppContext, FirebaseAppProvider, useFirebaseApp };
