import React, { useMemo, memo } from 'react';
import { Switch, Table } from 'antd';

import { CONFIDENCE_THRESHOLD, OVERALL_CONFIDENCE_THRESHOLD } from '../constants';
import { OutputSegment } from '../helper';

import './ConfidenceStats.less';

interface Props {
  segments: OutputSegment[];
  show: boolean;
  setShow: (v: boolean) => void;
}
const ConfidenceStats = (props: Props) => {
  const { segments, show, setShow } = props;

  const wordCount = useMemo(() => {
    return segments.reduce((sum, segment) => sum + segment.children.length, 0);
  }, [segments]);

  const veryConfident = useMemo(() => {
    return segments.reduce(
      (sum, segment) =>
        sum +
        segment.children.filter((child) => child.confidence >= CONFIDENCE_THRESHOLD.VERY).length,
      0
    );
  }, [segments]);

  const fairlyConfident = useMemo(() => {
    return segments.reduce(
      (sum, segment) =>
        sum +
        segment.children.filter(
          (child) =>
            child.confidence >= CONFIDENCE_THRESHOLD.FAIRLY &&
            child.confidence < CONFIDENCE_THRESHOLD.VERY
        ).length,
      0
    );
  }, [segments]);

  const slightlyConfident = useMemo(() => {
    return wordCount - veryConfident - fairlyConfident;
  }, [wordCount, veryConfident, fairlyConfident]);

  const overallConfidence = useMemo(() => {
    if (wordCount === 0) {
      return '';
    }
    const confidence = veryConfident / wordCount;
    if (confidence >= OVERALL_CONFIDENCE_THRESHOLD.FEW) {
      return 'Few edits required';
    } else if (confidence >= OVERALL_CONFIDENCE_THRESHOLD.MODERATE) {
      return 'Moderate edits required';
    } else {
      return 'Heavy edits required';
    }
  }, [wordCount, veryConfident]);

  const dataSource = useMemo(() => {
    return [
      {
        key: '1',
        color: '',
        level: 'Very confident',
        stats: `${((veryConfident / wordCount) * 100).toFixed(2)}% | ${veryConfident} words`,
        confidence: 'very',
      },
      {
        key: '2',
        color: '',
        level: 'Fairly confident',
        stats: `${((fairlyConfident / wordCount) * 100).toFixed(2)}% | ${fairlyConfident} words`,
        confidence: 'fairly',
      },
      {
        key: '3',
        color: '',
        level: 'Slightly confident',
        stats: `${((slightlyConfident / wordCount) * 100).toFixed(
          2
        )}% | ${slightlyConfident} words`,
        confidence: 'slightly',
      },
    ];
  }, [wordCount, veryConfident, fairlyConfident, slightlyConfident]);

  const columns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'color',
        key: 'color',
        render: (_, record) => <div className={`block-color ${record.confidence}`} />,
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        render: (text, record) => <div className={`level ${record.confidence}`}>{text}</div>,
      },
      {
        title: 'Statistics',
        dataIndex: 'stats',
        key: 'stats',
        render: (text, record) => <div className={`stats ${record.confidence}`}>{text}</div>,
      },
    ];
  }, []);

  if (wordCount === 0) {
    // TODO:
    return null;
  }

  return (
    <div className="confidence-root">
      <div className="confidence-header">
        <div className="confidence-overall">{overallConfidence}</div>
        <div className="confidence-switch">
          <span style={{ paddingRight: 10, fontSize: '80%' }}>Show confidence</span>
          <Switch checked={show} onChange={setShow} />
        </div>
      </div>

      <div className="confidence-details-header">Confidence</div>

      <div className="confidence-details-chart">
        <div
          className="confidence-details-very"
          style={{ width: `${(veryConfident / wordCount) * 100}%` }}
        />
        <div
          className="confidence-details-fairly"
          style={{ width: `${(fairlyConfident / wordCount) * 100}%` }}
        />
        <div
          className="confidence-details-slightly"
          style={{ width: `${(slightlyConfident / wordCount) * 100}%` }}
        />
      </div>

      <div className="confidence-details-table">
        <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
      </div>
    </div>
  );
};

export default memo(ConfidenceStats);
