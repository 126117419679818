/* istanbul ignore file */

import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Typography } from 'antd';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';

import { useUser } from '@/common/provider/auth';
import { useFirebaseApp } from '@/common/provider/firebase';

import LogoColor from '../../common/image/logo-color.png';

import './Login.less';
import { LANGUAGES } from '@/common/constant/upload';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/signed-in',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google',
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Facebook',
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Email',
    },
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};

const Login = () => {
  const firebaseApp = useFirebaseApp();
  const user = useUser();
  const supportedLanguages = Object.keys(LANGUAGES).length;

  if (user) {
    return <Redirect to="/signed-in" />;
  }

  return (
    <div className="login-root">
      <div className="login-box">
        <img src={LogoColor} alt="izitext.io" />
        <Typography.Title
          level={5}
          style={{
            color: 'gray',
            maxWidth: 228,
            margin: '0 auto',
            textAlign: 'center',
            fontSize: 13,
          }}
        >
          Your speech-to-text tool, supporting {supportedLanguages} languages worldwide
        </Typography.Title>
        <Typography.Title
          level={4}
          style={{
            marginTop: 12,
            marginBottom: 0,
          }}
        >
          Log in to your account
        </Typography.Title>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth()} />
      </div>
    </div>
  );
};

export default Login;
