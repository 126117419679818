import React from 'react';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';

const SplashScreen = () => {
  const intl = useIntl();
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100wh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin
        size="large"
        tip={intl.formatMessage(
          {
            description: 'Loading message',
            defaultMessage: "Please wait while we're loading your app",
          },
          {}
        )}
      />
    </div>
  );
};

export default SplashScreen;
