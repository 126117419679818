import React, { useMemo } from 'react';
import { Menu, Button } from 'antd';
import { ClockCircleOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useAccount } from '@/common/provider/account';

interface Props {
  collapsed: boolean;
}

const MainMenu = (props: Props) => {
  const { collapsed } = props;

  const { balance } = useAccount();

  const hourLeft = useMemo(() => {
    return Math.floor(balance / 60 / 60);
  }, [balance]);
  const minuteLeft = useMemo(() => {
    return Math.floor(balance / 60 - hourLeft * 60);
  }, [balance, hourLeft]);
  const secondLeft = useMemo(() => {
    return balance % 60;
  }, [balance]);

  const timeString = useMemo(() => {
    const parts: string[] = [];
    if (hourLeft > 0) {
      parts.push(`${hourLeft} hours`);
    }
    if (minuteLeft > 0) {
      parts.push(`${minuteLeft} minutes`);
    }
    if (secondLeft > 0) {
      parts.push(`${secondLeft} seconds`);
    }
    return parts.length > 0 ? parts.join(', ') : '0 seconds';
  }, [hourLeft, minuteLeft, secondLeft]);

  return (
    <Menu mode="inline" selectedKeys={[]}>
      <Menu.Item key="top">
        <Link to="/">
          <HomeOutlined />
          {!collapsed && <FormattedMessage description="Top menu text" defaultMessage="Top" />}
        </Link>
      </Menu.Item>
      <Menu.Item key="recent">
        <Link to="/recent">
          <ClockCircleOutlined />
          {!collapsed && (
            <FormattedMessage description="Recent menu text" defaultMessage="Recent" />
          )}
        </Link>
      </Menu.Item>
      {!collapsed && (
        <>
          <div style={{ padding: '10px 0', textAlign: 'center', lineHeight: '24px' }}>
            {timeString} left
            <br />
            <Link to="/plan">
              <Button style={{ marginTop: 15 }}>Your plan</Button>
            </Link>
          </div>
        </>
      )}
    </Menu>
  );
};

export default MainMenu;
