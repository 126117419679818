import padStart from 'lodash/padStart';

export const formatLength = (_length: number) => {
  let length = _length;
  const hours = Math.floor(length / 3600);
  length %= 3600;
  const minutes = Math.floor(length / 60);
  length %= 60;
  const seconds = length;
  return `${padStart(hours.toString(), 2, '0')}:${padStart(minutes.toString(), 2, '0')}:${padStart(
    seconds.toString(),
    2,
    '0'
  )}`;
};

export const formatTimestamp = (_time: string, showHours = true) => {
  let time = Math.round(parseFloat(_time));
  const hours = Math.floor(time / 3600);
  time %= 3600;
  const minutes = Math.floor(time / 60);
  time %= 60;
  const seconds = time;
  if (showHours) {
    return `${padStart(hours.toString(), 2, '0')}:${padStart(
      minutes.toString(),
      2,
      '0'
    )}:${padStart(seconds.toString(), 2, '0')}`;
  }

  return `${padStart(minutes.toString(), 2, '0')}:${padStart(seconds.toString(), 2, '0')}`;
};
