import * as Types from './operations';

import * as Apollo from '@apollo/client';
const gql = Apollo.gql;

export const FileContentFragmentFragmentDoc = gql`
  fragment FileContentFragment on File {
    id
    name
    isFolder
    createdAt
    transcription {
      id
      status
      length
    }
  }
`;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription {
    cancelSubscription {
      id
      balance
      userId
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  Types.CancelSubscriptionMutation,
  Types.CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CancelSubscriptionMutation,
    Types.CancelSubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.CancelSubscriptionMutation,
    Types.CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, baseOptions);
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<
  Types.CancelSubscriptionMutation
>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.CancelSubscriptionMutation,
  Types.CancelSubscriptionMutationVariables
>;
export const CreateAccountIfNotExistDocument = gql`
  mutation CreateAccountIfNotExist {
    createAccountIfNotExist {
      id
      balance
      userId
      plan
    }
  }
`;
export type CreateAccountIfNotExistMutationFn = Apollo.MutationFunction<
  Types.CreateAccountIfNotExistMutation,
  Types.CreateAccountIfNotExistMutationVariables
>;

/**
 * __useCreateAccountIfNotExistMutation__
 *
 * To run a mutation, you first call `useCreateAccountIfNotExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountIfNotExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountIfNotExistMutation, { data, loading, error }] = useCreateAccountIfNotExistMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccountIfNotExistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAccountIfNotExistMutation,
    Types.CreateAccountIfNotExistMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.CreateAccountIfNotExistMutation,
    Types.CreateAccountIfNotExistMutationVariables
  >(CreateAccountIfNotExistDocument, baseOptions);
}
export type CreateAccountIfNotExistMutationHookResult = ReturnType<
  typeof useCreateAccountIfNotExistMutation
>;
export type CreateAccountIfNotExistMutationResult = Apollo.MutationResult<
  Types.CreateAccountIfNotExistMutation
>;
export type CreateAccountIfNotExistMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAccountIfNotExistMutation,
  Types.CreateAccountIfNotExistMutationVariables
>;
export const CreateFolderDocument = gql`
  mutation CreateFolder($data: NewFolderInput!) {
    newFolder(data: $data) {
      ...FileContentFragment
    }
  }
  ${FileContentFragmentFragmentDoc}
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<
  Types.CreateFolderMutation,
  Types.CreateFolderMutationVariables
>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateFolderMutation,
    Types.CreateFolderMutationVariables
  >
) {
  return Apollo.useMutation<Types.CreateFolderMutation, Types.CreateFolderMutationVariables>(
    CreateFolderDocument,
    baseOptions
  );
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<Types.CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateFolderMutation,
  Types.CreateFolderMutationVariables
>;
export const CreatePurchaseDocument = gql`
  mutation CreatePurchase($hours: Int!, $pid: String!) {
    newPurchase(hours: $hours, pid: $pid) {
      id
    }
  }
`;
export type CreatePurchaseMutationFn = Apollo.MutationFunction<
  Types.CreatePurchaseMutation,
  Types.CreatePurchaseMutationVariables
>;

/**
 * __useCreatePurchaseMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseMutation, { data, loading, error }] = useCreatePurchaseMutation({
 *   variables: {
 *      hours: // value for 'hours'
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useCreatePurchaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePurchaseMutation,
    Types.CreatePurchaseMutationVariables
  >
) {
  return Apollo.useMutation<Types.CreatePurchaseMutation, Types.CreatePurchaseMutationVariables>(
    CreatePurchaseDocument,
    baseOptions
  );
}
export type CreatePurchaseMutationHookResult = ReturnType<typeof useCreatePurchaseMutation>;
export type CreatePurchaseMutationResult = Apollo.MutationResult<Types.CreatePurchaseMutation>;
export type CreatePurchaseMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePurchaseMutation,
  Types.CreatePurchaseMutationVariables
>;
export const GetFileDocument = gql`
  query GetFile($id: Int) {
    file(id: $id) {
      ...FileContentFragment
    }
  }
  ${FileContentFragmentFragmentDoc}
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetFileQuery, Types.GetFileQueryVariables>
) {
  return Apollo.useQuery<Types.GetFileQuery, Types.GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export function useGetFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFileQuery, Types.GetFileQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetFileQuery, Types.GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<
  Types.GetFileQuery,
  Types.GetFileQueryVariables
>;
export const GetFileContentDocument = gql`
  query GetFileContent($id: Int, $skip: Int = 0, $take: Int = 25) {
    file(id: $id) {
      id
      name
      isFolder
      level
      parent {
        id
        name
        parent {
          id
          name
        }
      }
    }
    fileChildren(id: $id, skip: $skip, take: $take)
      @connection(key: "fileChildren", filter: ["id"]) {
      total
      items {
        ...FileContentFragment
      }
    }
  }
  ${FileContentFragmentFragmentDoc}
`;

/**
 * __useGetFileContentQuery__
 *
 * To run a query within a React component, call `useGetFileContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetFileContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetFileContentQuery,
    Types.GetFileContentQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetFileContentQuery, Types.GetFileContentQueryVariables>(
    GetFileContentDocument,
    baseOptions
  );
}
export function useGetFileContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetFileContentQuery,
    Types.GetFileContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetFileContentQuery, Types.GetFileContentQueryVariables>(
    GetFileContentDocument,
    baseOptions
  );
}
export type GetFileContentQueryHookResult = ReturnType<typeof useGetFileContentQuery>;
export type GetFileContentLazyQueryHookResult = ReturnType<typeof useGetFileContentLazyQuery>;
export type GetFileContentQueryResult = Apollo.QueryResult<
  Types.GetFileContentQuery,
  Types.GetFileContentQueryVariables
>;
export const GetFilesDocument = gql`
  query GetFiles($ids: [Int!]!) {
    files(ids: $ids) {
      id
      name
      isFolder
      transcription {
        length
        status
      }
    }
  }
`;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetFilesQuery, Types.GetFilesQueryVariables>
) {
  return Apollo.useQuery<Types.GetFilesQuery, Types.GetFilesQueryVariables>(
    GetFilesDocument,
    baseOptions
  );
}
export function useGetFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilesQuery, Types.GetFilesQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetFilesQuery, Types.GetFilesQueryVariables>(
    GetFilesDocument,
    baseOptions
  );
}
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<typeof useGetFilesLazyQuery>;
export type GetFilesQueryResult = Apollo.QueryResult<
  Types.GetFilesQuery,
  Types.GetFilesQueryVariables
>;
export const GetLifetimeLinkDocument = gql`
  query GetLifetimeLink($pid: String!) {
    lifetimeLink(pid: $pid)
  }
`;

/**
 * __useGetLifetimeLinkQuery__
 *
 * To run a query within a React component, call `useGetLifetimeLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifetimeLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifetimeLinkQuery({
 *   variables: {
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetLifetimeLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetLifetimeLinkQuery,
    Types.GetLifetimeLinkQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetLifetimeLinkQuery, Types.GetLifetimeLinkQueryVariables>(
    GetLifetimeLinkDocument,
    baseOptions
  );
}
export function useGetLifetimeLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetLifetimeLinkQuery,
    Types.GetLifetimeLinkQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetLifetimeLinkQuery, Types.GetLifetimeLinkQueryVariables>(
    GetLifetimeLinkDocument,
    baseOptions
  );
}
export type GetLifetimeLinkQueryHookResult = ReturnType<typeof useGetLifetimeLinkQuery>;
export type GetLifetimeLinkLazyQueryHookResult = ReturnType<typeof useGetLifetimeLinkLazyQuery>;
export type GetLifetimeLinkQueryResult = Apollo.QueryResult<
  Types.GetLifetimeLinkQuery,
  Types.GetLifetimeLinkQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      balance
      plan
      isSubscriptionEffective
      nextPayment
      subscriptionStatus
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetMeQuery, Types.GetMeQueryVariables>
) {
  return Apollo.useQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, baseOptions);
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMeQuery, Types.GetMeQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(
    GetMeDocument,
    baseOptions
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<Types.GetMeQuery, Types.GetMeQueryVariables>;
export const GetPayLinkDocument = gql`
  query GetPayLink($hours: Int!, $pid: String!) {
    payLink(hours: $hours, pid: $pid)
  }
`;

/**
 * __useGetPayLinkQuery__
 *
 * To run a query within a React component, call `useGetPayLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayLinkQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetPayLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetPayLinkQuery, Types.GetPayLinkQueryVariables>
) {
  return Apollo.useQuery<Types.GetPayLinkQuery, Types.GetPayLinkQueryVariables>(
    GetPayLinkDocument,
    baseOptions
  );
}
export function useGetPayLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPayLinkQuery, Types.GetPayLinkQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetPayLinkQuery, Types.GetPayLinkQueryVariables>(
    GetPayLinkDocument,
    baseOptions
  );
}
export type GetPayLinkQueryHookResult = ReturnType<typeof useGetPayLinkQuery>;
export type GetPayLinkLazyQueryHookResult = ReturnType<typeof useGetPayLinkLazyQuery>;
export type GetPayLinkQueryResult = Apollo.QueryResult<
  Types.GetPayLinkQuery,
  Types.GetPayLinkQueryVariables
>;
export const GetPriceDocument = gql`
  query GetPrice($hours: Int!) {
    price(hours: $hours)
  }
`;

/**
 * __useGetPriceQuery__
 *
 * To run a query within a React component, call `useGetPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useGetPriceQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetPriceQuery, Types.GetPriceQueryVariables>
) {
  return Apollo.useQuery<Types.GetPriceQuery, Types.GetPriceQueryVariables>(
    GetPriceDocument,
    baseOptions
  );
}
export function useGetPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPriceQuery, Types.GetPriceQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetPriceQuery, Types.GetPriceQueryVariables>(
    GetPriceDocument,
    baseOptions
  );
}
export type GetPriceQueryHookResult = ReturnType<typeof useGetPriceQuery>;
export type GetPriceLazyQueryHookResult = ReturnType<typeof useGetPriceLazyQuery>;
export type GetPriceQueryResult = Apollo.QueryResult<
  Types.GetPriceQuery,
  Types.GetPriceQueryVariables
>;
export const GetRecentFilesDocument = gql`
  query GetRecentFiles($skip: Int = 0, $take: Int = 25) {
    recentFiles(skip: $skip, take: $take) {
      total
      items {
        ...FileContentFragment
      }
    }
  }
  ${FileContentFragmentFragmentDoc}
`;

/**
 * __useGetRecentFilesQuery__
 *
 * To run a query within a React component, call `useGetRecentFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentFilesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRecentFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetRecentFilesQuery,
    Types.GetRecentFilesQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetRecentFilesQuery, Types.GetRecentFilesQueryVariables>(
    GetRecentFilesDocument,
    baseOptions
  );
}
export function useGetRecentFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetRecentFilesQuery,
    Types.GetRecentFilesQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetRecentFilesQuery, Types.GetRecentFilesQueryVariables>(
    GetRecentFilesDocument,
    baseOptions
  );
}
export type GetRecentFilesQueryHookResult = ReturnType<typeof useGetRecentFilesQuery>;
export type GetRecentFilesLazyQueryHookResult = ReturnType<typeof useGetRecentFilesLazyQuery>;
export type GetRecentFilesQueryResult = Apollo.QueryResult<
  Types.GetRecentFilesQuery,
  Types.GetRecentFilesQueryVariables
>;
export const GetSubscribeLinkDocument = gql`
  query GetSubscribeLink($plan: String!, $pid: String!) {
    subscribeLink(plan: $plan, pid: $pid)
  }
`;

/**
 * __useGetSubscribeLinkQuery__
 *
 * To run a query within a React component, call `useGetSubscribeLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribeLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribeLinkQuery({
 *   variables: {
 *      plan: // value for 'plan'
 *      pid: // value for 'pid'
 *   },
 * });
 */
export function useGetSubscribeLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetSubscribeLinkQuery,
    Types.GetSubscribeLinkQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetSubscribeLinkQuery, Types.GetSubscribeLinkQueryVariables>(
    GetSubscribeLinkDocument,
    baseOptions
  );
}
export function useGetSubscribeLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetSubscribeLinkQuery,
    Types.GetSubscribeLinkQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetSubscribeLinkQuery, Types.GetSubscribeLinkQueryVariables>(
    GetSubscribeLinkDocument,
    baseOptions
  );
}
export type GetSubscribeLinkQueryHookResult = ReturnType<typeof useGetSubscribeLinkQuery>;
export type GetSubscribeLinkLazyQueryHookResult = ReturnType<typeof useGetSubscribeLinkLazyQuery>;
export type GetSubscribeLinkQueryResult = Apollo.QueryResult<
  Types.GetSubscribeLinkQuery,
  Types.GetSubscribeLinkQueryVariables
>;
export const GetTranscriptionDocument = gql`
  query GetTranscription($id: Int) {
    file(id: $id) {
      id
      name
      isFolder
      level
      signedUrl
      parent {
        id
        name
        parent {
          id
          name
        }
      }
      transcription {
        id
        status
        length
        originDocument {
          id
          originalContent
          finalContent
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetTranscriptionQuery__
 *
 * To run a query within a React component, call `useGetTranscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetTranscriptionQuery,
    Types.GetTranscriptionQueryVariables
  >
) {
  return Apollo.useQuery<Types.GetTranscriptionQuery, Types.GetTranscriptionQueryVariables>(
    GetTranscriptionDocument,
    baseOptions
  );
}
export function useGetTranscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTranscriptionQuery,
    Types.GetTranscriptionQueryVariables
  >
) {
  return Apollo.useLazyQuery<Types.GetTranscriptionQuery, Types.GetTranscriptionQueryVariables>(
    GetTranscriptionDocument,
    baseOptions
  );
}
export type GetTranscriptionQueryHookResult = ReturnType<typeof useGetTranscriptionQuery>;
export type GetTranscriptionLazyQueryHookResult = ReturnType<typeof useGetTranscriptionLazyQuery>;
export type GetTranscriptionQueryResult = Apollo.QueryResult<
  Types.GetTranscriptionQuery,
  Types.GetTranscriptionQueryVariables
>;
export const OnAccountUpdatedDocument = gql`
  subscription OnAccountUpdated {
    accountUpdated {
      id
      balance
    }
  }
`;

/**
 * __useOnAccountUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAccountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAccountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAccountUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAccountUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Types.OnAccountUpdatedSubscription,
    Types.OnAccountUpdatedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    Types.OnAccountUpdatedSubscription,
    Types.OnAccountUpdatedSubscriptionVariables
  >(OnAccountUpdatedDocument, baseOptions);
}
export type OnAccountUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnAccountUpdatedSubscription
>;
export type OnAccountUpdatedSubscriptionResult = Apollo.SubscriptionResult<
  Types.OnAccountUpdatedSubscription
>;
export const OnFileCreatedDocument = gql`
  subscription OnFileCreated($parentId: Int) {
    fileCreated(parentId: $parentId) {
      ...FileContentFragment
    }
  }
  ${FileContentFragmentFragmentDoc}
`;

/**
 * __useOnFileCreatedSubscription__
 *
 * To run a query within a React component, call `useOnFileCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFileCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFileCreatedSubscription({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnFileCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Types.OnFileCreatedSubscription,
    Types.OnFileCreatedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    Types.OnFileCreatedSubscription,
    Types.OnFileCreatedSubscriptionVariables
  >(OnFileCreatedDocument, baseOptions);
}
export type OnFileCreatedSubscriptionHookResult = ReturnType<typeof useOnFileCreatedSubscription>;
export type OnFileCreatedSubscriptionResult = Apollo.SubscriptionResult<
  Types.OnFileCreatedSubscription
>;
export const OnFileUpdatedDocument = gql`
  subscription OnFileUpdated($ids: [Int!]!) {
    fileUpdated(ids: $ids) {
      ...FileContentFragment
      transcription {
        length
      }
    }
  }
  ${FileContentFragmentFragmentDoc}
`;

/**
 * __useOnFileUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnFileUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFileUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFileUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useOnFileUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Types.OnFileUpdatedSubscription,
    Types.OnFileUpdatedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    Types.OnFileUpdatedSubscription,
    Types.OnFileUpdatedSubscriptionVariables
  >(OnFileUpdatedDocument, baseOptions);
}
export type OnFileUpdatedSubscriptionHookResult = ReturnType<typeof useOnFileUpdatedSubscription>;
export type OnFileUpdatedSubscriptionResult = Apollo.SubscriptionResult<
  Types.OnFileUpdatedSubscription
>;
export const RedeemLifetimeCouponDocument = gql`
  mutation RedeemLifetimeCoupon($coupon: String!) {
    redeemLifetimeCoupon(coupon: $coupon)
  }
`;
export type RedeemLifetimeCouponMutationFn = Apollo.MutationFunction<
  Types.RedeemLifetimeCouponMutation,
  Types.RedeemLifetimeCouponMutationVariables
>;

/**
 * __useRedeemLifetimeCouponMutation__
 *
 * To run a mutation, you first call `useRedeemLifetimeCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemLifetimeCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemLifetimeCouponMutation, { data, loading, error }] = useRedeemLifetimeCouponMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useRedeemLifetimeCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RedeemLifetimeCouponMutation,
    Types.RedeemLifetimeCouponMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.RedeemLifetimeCouponMutation,
    Types.RedeemLifetimeCouponMutationVariables
  >(RedeemLifetimeCouponDocument, baseOptions);
}
export type RedeemLifetimeCouponMutationHookResult = ReturnType<
  typeof useRedeemLifetimeCouponMutation
>;
export type RedeemLifetimeCouponMutationResult = Apollo.MutationResult<
  Types.RedeemLifetimeCouponMutation
>;
export type RedeemLifetimeCouponMutationOptions = Apollo.BaseMutationOptions<
  Types.RedeemLifetimeCouponMutation,
  Types.RedeemLifetimeCouponMutationVariables
>;
export const SaveDocumentDocument = gql`
  mutation SaveDocument($data: SaveDocumentInput!) {
    saveDocument(data: $data) {
      id
      originalContent
      finalContent
      updatedAt
    }
  }
`;
export type SaveDocumentMutationFn = Apollo.MutationFunction<
  Types.SaveDocumentMutation,
  Types.SaveDocumentMutationVariables
>;

/**
 * __useSaveDocumentMutation__
 *
 * To run a mutation, you first call `useSaveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentMutation, { data, loading, error }] = useSaveDocumentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveDocumentMutation,
    Types.SaveDocumentMutationVariables
  >
) {
  return Apollo.useMutation<Types.SaveDocumentMutation, Types.SaveDocumentMutationVariables>(
    SaveDocumentDocument,
    baseOptions
  );
}
export type SaveDocumentMutationHookResult = ReturnType<typeof useSaveDocumentMutation>;
export type SaveDocumentMutationResult = Apollo.MutationResult<Types.SaveDocumentMutation>;
export type SaveDocumentMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveDocumentMutation,
  Types.SaveDocumentMutationVariables
>;
export const UploadAndTranscribeDocument = gql`
  mutation UploadAndTranscribe($files: [UploadTranscribeInput!]!) {
    transcribe(files: $files)
  }
`;
export type UploadAndTranscribeMutationFn = Apollo.MutationFunction<
  Types.UploadAndTranscribeMutation,
  Types.UploadAndTranscribeMutationVariables
>;

/**
 * __useUploadAndTranscribeMutation__
 *
 * To run a mutation, you first call `useUploadAndTranscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAndTranscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAndTranscribeMutation, { data, loading, error }] = useUploadAndTranscribeMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadAndTranscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadAndTranscribeMutation,
    Types.UploadAndTranscribeMutationVariables
  >
) {
  return Apollo.useMutation<
    Types.UploadAndTranscribeMutation,
    Types.UploadAndTranscribeMutationVariables
  >(UploadAndTranscribeDocument, baseOptions);
}
export type UploadAndTranscribeMutationHookResult = ReturnType<
  typeof useUploadAndTranscribeMutation
>;
export type UploadAndTranscribeMutationResult = Apollo.MutationResult<
  Types.UploadAndTranscribeMutation
>;
export type UploadAndTranscribeMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadAndTranscribeMutation,
  Types.UploadAndTranscribeMutationVariables
>;
