import React, { useMemo, useContext, useEffect, useState, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote as fasStickyNote } from '@fortawesome/free-solid-svg-icons';

import { formatTimestamp } from '@/common/helper/time';

import { OutputSegment } from '../helper';
import { EditorContext } from '../EditorContainer';

import './NoteList.less';

interface Props {
  open: boolean;
  seekTo: (pos: number) => void;
}
const NoteList = (props: Props) => {
  const { seekTo, open } = props;

  const editor = useContext(EditorContext);

  const [segments, setSegments] = useState<OutputSegment[]>([]);

  const notes = useMemo(() => {
    return segments
      .filter((s) => s.note)
      .map((s) => ({
        note: s.note as string,
        start: s.start_time,
      }));
  }, [segments]);

  useEffect(() => {
    if (open) {
      setSegments(editor.getSegments());
    }
  }, [open, editor]);

  return (
    <div className="note-list-root">
      <div className="note-list-header">Notes</div>
      <ul>
        {notes.map((note) => (
          <li key={note.start}>
            <div className="note-timestamp" onClick={() => seekTo(note.start)}>
              <FontAwesomeIcon icon={fasStickyNote} className="note-icon" />
              <span>{formatTimestamp(note.start.toString(), true)}</span>
            </div>
            <p className="note-content">{note.note}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(NoteList);
