import React, { useCallback, useMemo, useState } from 'react';
import { Spin, Table } from 'antd';
import { PlayCircleOutlined, FolderOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { FormattedDate, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useAntdMediaQuery from 'use-media-antd-query';

import { formatLength } from '@/common/helper/time';

// import ContextMenu, { ContextMenuPosition } from '../ContextMenu';

import './FileTable.less';

interface IFile {
  id: string;
  name: string;
  isFolder: boolean;
  createdAt: any;
  transcription?: any;
}

interface Props<T extends IFile> {
  data: T[];
  total: number;
  loading: boolean;
  loadMore: () => void;
  loadingMore: boolean;
}
function FileTable<T extends IFile>(props: Props<T>) {
  const { data, total, loading, loadMore, loadingMore } = props;

  const screenSize = useAntdMediaQuery();
  const intl = useIntl();
  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState<T | null>(null);
  // const [menuPosition, setMenuPosition] = useState<ContextMenuPosition>({
  //   visible: false,
  //   x: 0,
  //   y: 0,
  // });

  // const { visible: menuVisible } = menuPosition;

  const createOnRowClickHandler = useCallback((file: T) => {
    return () => setSelectedFile((f) => (f?.id === file.id ? null : file));
  }, []);

  const createOnRowDoubleClickHandler = useCallback(
    (file: T) => {
      return () => {
        if (file.isFolder) {
          history.push(`/f/${file.id}`);
        } else if (file.transcription && file.transcription.status === 'READY') {
          history.push(`/t/${file.id}`);
        }
      };
    },
    [history]
  );

  // const createOnRowContextMenuHandler = useCallback((file: T) => {
  //   return (e) => {
  //     e.preventDefault();
  //     // TODO: Handle context menu in later iteration
  //     // setSelectedFile(file);
  //     // setMenuPosition({ visible: true, x: e.clientX, y: e.clientY });
  //   };
  // }, []);

  // const handleContextMenuSelect = useCallback(
  //   (key) => {
  //     /* eslint-disable-next-line */
  //     console.log(key, selectedFile);
  //   },
  //   [selectedFile]
  // );

  const footer = useMemo(() => {
    if (data.length === total) {
      return undefined;
    }
    return () =>
      loadingMore ? (
        <div className="table-load-more">
          <Spin />
        </div>
      ) : (
        <div className="table-load-more" onClick={loadMore}>
          Load more
        </div>
      );
  }, [loadMore, loadingMore, data, total]);

  // useEffect(() => {
  //   function clickOutside() {
  //     setMenuPosition((pos) => ({ ...pos, visible: false }));
  //   }

  //   if (menuVisible) {
  //     document.addEventListener('click', clickOutside);

  //     return () => document.removeEventListener('click', clickOutside);
  //   }
  // }, [menuVisible]);

  return (
    <>
      <Table<T>
        dataSource={data}
        className="home-file-list"
        pagination={false}
        rowKey="id"
        onRow={(file, _index) => ({
          onClick: createOnRowClickHandler(file),
          onDoubleClick: createOnRowDoubleClickHandler(file),
          // onContextMenu: createOnRowContextMenuHandler(file),
        })}
        rowClassName={(file) =>
          clsx({
            'row-selected': selectedFile && file.id === selectedFile.id,
          })
        }
        loading={loading}
        footer={footer}
      >
        <Table.Column<T>
          title={intl.formatMessage({
            description: "Home table's name column title",
            defaultMessage: 'Name',
          })}
          dataIndex="name"
          render={(text, file) => (
            <div className="home-file-name">
              {file.isFolder ? <FolderOutlined /> : <PlayCircleOutlined />}&nbsp; {text}
            </div>
          )}
        />
        {screenSize !== 'xs' && screenSize !== 'sm' && (
          <>
            <Table.Column<T>
              title={intl.formatMessage({
                description: "Home table's status column title",
                defaultMessage: 'Status',
              })}
              dataIndex="status"
              width="150px"
              render={(text, file) => (file.isFolder ? null : file.transcription?.status)}
            />
            <Table.Column<T>
              title={intl.formatMessage({
                description: "Home table's length column title",
                defaultMessage: 'Length',
              })}
              dataIndex="length"
              width="100px"
              render={(text, file) =>
                file.isFolder ? null : formatLength(file.transcription?.length)
              }
            />
            <Table.Column<T>
              title={intl.formatMessage({
                description: "Home table's uploaded column title",
                defaultMessage: 'Uploaded',
              })}
              dataIndex="createdAt"
              width="100px"
              render={(text, file) =>
                file.isFolder ? null : <FormattedDate value={new Date(text)} />
              }
            />
          </>
        )}

        {/*
        <ContextMenu position={menuPosition} onSelect={handleContextMenuSelect} />
        */}
      </Table>
    </>
  );
}

export default FileTable;
