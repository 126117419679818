export const ALLOWED_FILE_TYPES = [
  '.mp3',
  '.wav',
  '.flac',
  '.aac',
  '.m4a',
  '.mp4',
  '.wma',
  '.webm',
  '.ogg',
  '.mov',
  '.wmv',
  '.flv',
  '.avi',
  '.mkv',
];

export const ALLOWED_MIME_TYPES = [
  'audio/flac',
  'audio/mpeg',
  'audio/wav',
  'audio/aac',
  'audio/x-m4a',
  'video/mp4',
  'video/x-ms-wma',
  'video/webm',
  'audio/ogg',
  'video/avi',
  'video/x-matroska',
  'video/quicktime',
  'video/x-flv',
  'video/x-ms-wmv',
];

export const LANGUAGES = {
  'en-US': 'English, US (en-US)',
  'en-AB': 'English, Scottish (en-AB)',
  'en-AU': 'English, Australian (en-AU)',
  'en-GB': 'English, British (en-GB)',
  'en-IE': 'English, Irish (en-IE)',
  'en-IN': 'English, Indian (en-IN)',
  'en-WL': 'English, Welsh (en-WL)',
  'ar-AE': 'Arabic, Gulf (ar-AE)',
  'ar-SA': 'Arabic, Modern Standard (ar-SA)',
  'de-DE': 'German (de-DE)',
  'de-CH': 'German, Swiss (de-CH)',
  'es-ES': 'Spanish (es-ES)',
  'es-US': 'Spanish, US (es-US)',
  'fa-IR': 'Farsi (fa-IR)',
  'fr-FR': 'French (fr-FR)',
  'fr-CA': 'French, Canadian (fr-CA)',
  'he-IL': 'Hebrew (he-IL)',
  'hi-IN': 'Hindi, Indian (hi-IN)',
  'id-ID': 'Indonesian (id-ID)',
  'it-IT': 'Italian (it-IT)',
  'ja-JP': 'Japanese (ja-JP)',
  'ko-KR': 'Korean (ko-KR)',
  'ms-MY': 'Malay (ms-MY)',
  'nl-NL': 'Dutch (nl-NL)',
  'pt-PT': 'Portuguese (pt-PT)',
  'pt-BR': 'Portuguese, Brazilian (pt-BR)',
  'ru-RU': 'Russian (ru-RU)',
  'ta-IN': 'Tamil (ta-IN)',
  'te-IN': 'Telugu (te-IN)',
  'tr-TR': 'Turkish (tr-TR)',
  'zh-CN': 'Mandarin Chinese - Mainland (zh-CN)',
};

export const MAX_NUMBER_OF_SPEAKERS = 10;
