import React, { useContext, useCallback, memo } from 'react';
import { Checkbox } from 'antd';

import { EditorContext } from '../EditorContainer';

interface Props {
  element: any;
}
const CompleteCheckbox = (props: Props) => {
  const { element } = props;

  const editor = useContext(EditorContext);

  const onToggleComplete = useCallback(() => {
    editor.toggleComplete(element.id);
  }, [editor, element]);

  return <Checkbox onChange={onToggleComplete} checked={element.complete} />;
};

export default memo(CompleteCheckbox);
