import React, { useContext, useState, useCallback } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { ID_TOKEN_KEY } from '@/common/provider/auth';

import { FileContext } from '../EditorContainer';

import SrtExportOption from './SrtExportOption';
import TxtExportOption from './TxtExportOption';

interface Props {
  savingDocument: boolean;
}
const ExportModal = (props: Props) => {
  const { savingDocument } = props;
  const [form] = Form.useForm();

  const file = useContext(FileContext);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const documentId = file.transcription.originDocument.id;

  const handleSubmit = useCallback(
    (values) => {
      const token = localStorage.getItem(ID_TOKEN_KEY);
      const data: any = {
        type: values.type.toUpperCase(),
      };
      switch (data.type) {
        case 'TXT':
          data.txtOptions = {
            ...values.txtOptions,
            timecodePeriod: values.txtOptions.includeTimecodeEveryPeriod
              ? values.txtOptions.timecodePeriod
              : undefined,
          };
          break;
        case 'SRT':
          data.srtOptions = values.srtOptions;
          break;
      }
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/documents/${documentId}/export`, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          const { data } = response;
          window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/documents/${documentId}/export?key=${data}&token=${token}`;
          setVisible(false);
          setLoading(false);
          form.resetFields();
        });
    },
    [documentId, form]
  );

  return (
    <div>
      <Button onClick={() => setVisible(true)} disabled={savingDocument} type="primary">
        <DownloadOutlined /> Export
      </Button>
      <Modal
        title="Export transcript"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          form={form}
          initialValues={{
            type: undefined,
            txtOptions: {
              includeTimecodeParagraph: true,
              includeTimecodeMs: false,
              includeTimecodeEveryPeriod: false,
              includeSpeakerName: true,
              timecodePeriod: 30,
            },
            srtOptions: {
              showSpeakerName: 'NO',
              split: 2,
              maxLength: 90,
              maxDuration: 10,
              breakOverride: false,
            },
          }}
          onFinish={handleSubmit}
          layout="vertical"
          colon={false}
        >
          <Form.Item
            label="Export format"
            name="type"
            rules={[{ required: true, message: 'Please choose an export format' }]}
          >
            <Select style={{ width: '100%' }} placeholder="Please select">
              <Select.OptGroup key="text" label="Text">
                <Select.Option value="txt">Text file (*.txt)</Select.Option>
              </Select.OptGroup>
              <Select.OptGroup key="subtitle" label="Subtitles">
                <Select.Option value="srt">SubRip subtitle file (*.srt)</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {() => {
              switch (form.getFieldValue('type')) {
                case 'txt':
                  return <TxtExportOption forms={[form]} />;
                case 'srt':
                  return <SrtExportOption forms={[form]} />;
              }
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {() =>
              form.getFieldValue('type') ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={savingDocument}
                >
                  Download (*.{form.getFieldValue('type')})
                </Button>
              ) : null
            }
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ExportModal;
