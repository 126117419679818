import React from 'react';

import { useAccount } from '@/common/provider/account';

import LifetimePlanView from './component/LifetimePlanView';
import StandardPlanView from './component/StandardPlanView';
import PremiumPlanView from './component/PremiumPlanView';

const YourPlan = () => {
  const { plan, isSubscriptionEffective } = useAccount();

  if (plan === 'LIFETIME') {
    return <LifetimePlanView />;
  }

  if (plan === 'STANDARD' || !isSubscriptionEffective) {
    return <StandardPlanView />;
  }

  return <PremiumPlanView />;
};

export default YourPlan;
