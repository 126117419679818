import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography, Row, Col, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useGetSubscribeLinkLazyQuery } from '@/graphql/generated/hooks';

import PlanDetails from './component/PlanDetails';
import './Subscribe.less';

const Subscribe = () => {
  const history = useHistory();

  const [
    getSubscribeLink,
    { data: subscribeLinkData, loading: subscribeLinkLoading },
  ] = useGetSubscribeLinkLazyQuery();

  const [pid] = useState<string>(() => uuidv4());
  const [plan, setPlan] = useState<string>('annual');

  const handleCheckout = useCallback(() => {
    getSubscribeLink({
      variables: {
        plan,
        pid,
      },
    });
  }, [getSubscribeLink, pid, plan]);

  const triggerCheckout = useCallback(
    (url) => {
      function checkoutComplete(data) {
        history.push('/');
      }

      (window as any).Paddle.Checkout.open({
        override: url,
        successCallback: checkoutComplete,
      });
    },
    [history]
  );

  useEffect(() => {
    if (!(window as any).Paddle) {
      const script = document.createElement('script');
      script.src = 'https://cdn.paddle.com/paddle/paddle.js';
      script.onload = function initPaddle() {
        (window as any).Paddle.Setup({
          vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || '', 10),
        });
      };
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (subscribeLinkData) {
      triggerCheckout(subscribeLinkData.subscribeLink);
    }
  }, [subscribeLinkData, triggerCheckout]);

  const radioStyle = {
    display: 'block',
    lineHeight: '30px',
    marginBottom: '10px',
  };

  return (
    <div className="subscribe-root">
      <Typography.Title level={3}>Subscription plan</Typography.Title>
      <Row>
        <Col xs={24} md={16}>
          <div className="plan-container">
            <Typography.Title level={4}>Choose your subscription</Typography.Title>

            <div className="row">
              <Radio.Group onChange={(e) => setPlan(e.target.value)} value={plan}>
                <Radio style={radioStyle} value="annual">
                  Annual billing
                  <br />
                  <span style={{ paddingLeft: 25 }}>
                    $ {process.env.REACT_APP_ANNUAL_PRICE} for 12 months
                  </span>
                </Radio>
                <Radio style={radioStyle} value="monthly">
                  Monthly billing
                  <br />
                  <span style={{ paddingLeft: 25 }}>
                    $ {process.env.REACT_APP_MONTHLY_PRICE} / month
                  </span>
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <div className="checkout-container">
            <PlanDetails plan={plan} />
            <Button onClick={handleCheckout} loading={subscribeLinkLoading}>
              Continue to checkout
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Subscribe;
