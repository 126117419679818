import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  memo,
  createContext,
} from 'react';
import { Redirect, useParams, Prompt } from 'react-router-dom';
import { Spin } from 'antd';
import { Howl } from 'howler';
import { Node, createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import { useGetTranscriptionLazyQuery, useSaveDocumentMutation } from '@/graphql/generated/hooks';

import calculateSize from '@/common/helper/dom';

import EditorHeader from './component/EditorHeader';
import EditorCoreNew from './component/EditorCoreNew';

import { withTranscript, TranscriptEditor } from './plugins';
import { transform } from './helper';
import './Editor.less';

interface SoundContextValue {
  audioReady: boolean;
  duration: number;
  pos: number;
  playing: boolean;
  setPos: (pos: number) => void;
  sound: Howl | null;
}

export const FileContext = createContext<any>(null);
export const SoundContext = createContext<SoundContextValue>({
  audioReady: false,
  duration: 0,
  pos: 0,
  playing: false,
  setPos: () => {},
  sound: null,
});
export const EditorContext = createContext<TranscriptEditor>({} as any);
export const ViewManagerContext = createContext<{ start: number; end: number }>({
  start: 0,
  end: 0,
});

const ViewManagerProvider = memo(({ children, value }: any) => {
  return <ViewManagerContext.Provider value={value}>{children}</ViewManagerContext.Provider>;
});

const EditorContainer = () => {
  const { fileId }: { fileId: any } = useParams();

  const [getTranscription, { data, loading }] = useGetTranscriptionLazyQuery();
  const [saveDocument, { loading: savingDocument }] = useSaveDocumentMutation();

  const [audioReady, setAudioReady] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [sound, setSound] = useState<Howl | null>(null);
  const [duration, setDuration] = useState(0);
  const [pos, setPos] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [value, setValue] = useState<Node[]>([]);
  const [editing, setEditing] = useState(false);
  const [showConfidence, setShowConfidence] = useState(true);

  const [viewPortWidth, setViewPortWidth] = useState(0);
  const [position, setPosition] = useState<{ start: number; end: number }>({ start: 0, end: 0 });

  const contentRef = useRef<HTMLDivElement | null>(null);

  const editor = useMemo(() => withTranscript(withHistory(withReact(createEditor()))), []);

  const audioValue = useMemo(() => {
    return { audioReady, duration, pos, playing, sound, setPos };
  }, [audioReady, duration, pos, playing, sound]);

  const sectionHeights = useMemo(() => {
    if (viewPortWidth > 0 && contentRef.current) {
      const word = contentRef.current.querySelector('.teditor-segment-content .teditor-word');

      if (word) {
        const style = window.getComputedStyle(word);

        return value.map((section: any) => {
          const text = section.children.map((c: any) => c.text).join('');
          return (
            calculateSize(text, {
              font: style.fontFamily,
              fontSize: style.fontSize,
              lineHeight: style.lineHeight,
              width: viewPortWidth.toString() + 'px',
            }).height +
            25 +
            16
            // 25 padding top, 16 padding bottom
          );
        });
      }
    }

    return value.map(() => 0);
  }, [value, viewPortWidth]);

  // Handle document changes
  const onChange = useCallback(
    (newValue: Node[]) => {
      if (data && data.file && data.file.transcription) {
        return saveDocument({
          variables: {
            data: {
              transcriptionId: parseInt(data.file.transcription.id, 10),
              content: JSON.stringify({
                segments: newValue,
              }),
            },
          },
        });
      }
    },
    [data, saveDocument]
  );

  // Load transcription
  useEffect(() => {
    if (fileId && !isNaN(parseInt(fileId, 10))) {
      getTranscription({ variables: { id: parseInt(fileId, 10) } });
    }
  }, [getTranscription, fileId]);

  // Load audio url
  useEffect(() => {
    if (data && data.file && data.file.signedUrl && !audioUrl) {
      setAudioUrl(data.file.signedUrl);
    }
  }, [data, audioUrl]);

  // Load transcription data and transform
  useEffect(() => {
    if (data && data.file && data.file.transcription) {
      const val = transform(
        JSON.parse(data.file.transcription.originDocument.finalContent)
      ) as Node[];
      setValue(val);
    }
  }, [data]);

  // Load audio into howl
  useEffect(() => {
    if (audioUrl) {
      const _sound = new Howl({
        src: [audioUrl],
        autoplay: false,
        loop: false,
      });

      _sound.once('load', () => setAudioReady(true));
      _sound.on('play', () => setPlaying(true));
      _sound.on('pause', () => setPlaying(false));
      _sound.on('end', () => setPlaying(false));

      setSound(_sound);

      return () => {
        _sound.unload();
      };
    }
  }, [audioUrl]);

  // Update current audio pos and duration
  useEffect(() => {
    if (!audioReady) {
      return;
    }

    let id: any;

    function loop() {
      if (sound && sound.playing()) {
        setPos(parseFloat(sound.seek().toString()));
      }
      id = window.requestAnimationFrame(loop);
    }

    if (sound) {
      setDuration(sound.duration() || 0);
    }

    id = window.requestAnimationFrame(loop);

    return () => window.cancelAnimationFrame(id);
  }, [audioReady, sound]);

  // Unload audio when unmount
  useEffect(() => {
    return () => {
      sound?.unload();
    };
  }, []); // eslint-disable-line

  // Calculate working viewport
  useEffect(() => {
    function onResize() {
      if (contentRef.current) {
        const content = contentRef.current.querySelector('.teditor-segment-content');
        const rect = content?.getBoundingClientRect();

        if (rect) {
          setViewPortWidth(rect.width);
        }
      }
    }

    const debouncedOnResize = debounce(onResize, 150);

    window.addEventListener('resize', debouncedOnResize);

    debouncedOnResize();

    return () => window.removeEventListener('resize', debouncedOnResize);
  }, []);

  useEffect(() => {
    function onScroll() {
      if (sectionHeights[0] === 0 || !contentRef.current) {
        setPosition({ start: 0, end: 0 });
        return;
      }

      const { height: contentHeight } = contentRef.current.getBoundingClientRect();
      const scrollTop = contentRef.current.scrollTop;
      let start = 0;
      let end = sectionHeights.length - 1;
      let accumulate = 0;

      for (let i = 0; i < sectionHeights.length; i++) {
        if (accumulate <= scrollTop && accumulate + sectionHeights[i] > scrollTop) {
          start = i;
        }
        if (accumulate + sectionHeights[i] > scrollTop + contentHeight) {
          end = i;
          break;
        }
        accumulate += sectionHeights[i];
      }

      if (position.start !== start || position.end !== end) {
        setPosition({ start, end });
        editor.selection = null;
      }
    }

    const debouncedOnScroll = debounce(onScroll, 20);

    const ref = contentRef;

    if (ref.current) {
      ref.current.addEventListener('scroll', debouncedOnScroll);

      debouncedOnScroll();

      return () => ref.current?.removeEventListener('scroll', debouncedOnScroll);
    }
  }, [editor, sectionHeights, position]);

  if (!fileId || isNaN(parseInt(fileId, 10))) {
    // TODO:
    return <Redirect to="/" />;
  }

  if (loading || !data || !data.file) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 140px)',
        }}
      >
        <Spin size="large" tip="Loading transcription ..." />
      </div>
    );
  }

  return (
    <FileContext.Provider value={data.file}>
      <SoundContext.Provider value={audioValue}>
        <EditorContext.Provider value={editor}>
          {!showConfidence && (
            <style scoped>
              {`
                .teditor-segment .teditor-root .teditor-word {
                  color: #0a0a0a;
                }
              `}
            </style>
          )}

          <Prompt
            when={editing || savingDocument}
            message="Your changes have not been saved, are you sure you want to leave?"
          />

          <div
            className={clsx({
              'editor-root': true,
              'with-confidence': showConfidence,
            })}
          >
            <EditorHeader
              editing={editing}
              savingDocument={savingDocument}
              showConfidence={showConfidence}
              setShowConfidence={setShowConfidence}
            />

            <ViewManagerProvider value={position}>
              <EditorCoreNew
                value={value}
                onChange={onChange}
                setEditing={setEditing}
                contentRef={contentRef}
              />
            </ViewManagerProvider>
          </div>
        </EditorContext.Provider>
      </SoundContext.Provider>
    </FileContext.Provider>
  );

  // return (
  //   <FileContext.Provider value={data.file}>
  //     <SoundContext.Provider value={audioValue}>
  //       <EditorContext.Provider value={editor}>
  //         <Prompt
  //           when={editing || savingDocument}
  //           message="Your changes have not been saved, are you sure you want to leave?"
  //         />

  //         <div className="editor-root">
  //           <EditorHeader
  //             editing={editing}
  //             savingDocument={savingDocument}
  //             showConfidence={showConfidence}
  //             setShowConfidence={setShowConfidence}
  //           />

  //           <TestEditor
  //             value={value}
  //             onChange={onChange}
  //             showConfidence={showConfidence}
  //             setEditing={setEditing}
  //           />
  //           {/*
  //           <EditorHeader
  //             editing={editing}
  //             savingDocument={savingDocument}
  //             showConfidence={showConfidence}
  //             setShowConfidence={setShowConfidence}
  //           />

  //           <EditorCore
  //             value={value}
  //             onChange={onChange}
  //             showConfidence={showConfidence}
  //             setEditing={setEditing}
  //           />
  //             */}

  //           {/*
  //           <ViewManagerProvider value={value}>
  //             <EditorCore
  //               value={value}
  //               onChange={onChange}
  //               showConfidence={showConfidence}
  //               setEditing={setEditing}
  //             />
  //           </ViewManagerProvider>
  //           */}
  //         </div>
  //       </EditorContext.Provider>
  //     </SoundContext.Provider>
  //   </FileContext.Provider>
  // );
};

export default EditorContainer;
