import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import Uppy, { UppyFile } from '@uppy/core';
import { Button, Checkbox, Empty, Progress, Spin, Typography, Select } from 'antd';
import clsx from 'clsx';
import sum from 'lodash/sum';
import * as Sentry from '@sentry/react';
import range from 'lodash/range';

import { useAccount } from '@/common/provider/account';
import { formatLength } from '@/common/helper/time';
import { getMediaDuration } from '@/common/helper/file';
import { LANGUAGES, MAX_NUMBER_OF_SPEAKERS } from '@/common/constant/upload';

import './UploadProgress.less';

const maxFileDuration = process.env.REACT_APP_UPLOAD_DURATION_LIMIT
  ? parseInt(process.env.REACT_APP_UPLOAD_DURATION_LIMIT, 10)
  : undefined;

interface UploadProgress {
  bytesUploaded: number;
  bytesTotal: number;
}
interface UploadFile {
  file: UppyFile;
  uuid: string;
  length: number;
  progress: number;
  done: boolean;
  error?: boolean;
  exceededDuration?: boolean;
  language: string;
  numSpeaker: number;
}

interface Props {
  uppy: Uppy.Uppy<Uppy.StrictTypes>;
  onSubmit: (files: { uuid: string; language: string; maxSpeakerCount: number }[]) => void;
  submitting: boolean;
}
const UploadProgress = (props: Props) => {
  const { uppy, onSubmit, submitting } = props;

  const { balance } = useAccount();

  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const uploadRef = useRef<any>(null);

  const fileAdded = useCallback(
    (file: UppyFile) => {
      getMediaDuration(file.data)
        .then((duration) => {
          if (maxFileDuration && duration > maxFileDuration) {
            uppy.info('File duration limit exceeded', 'error');
            uppy.removeFile(file.id);
          } else {
            uppy.setFileMeta(file.id, {
              length: duration,
            });
            setUploadFiles((files) =>
              files.concat([
                {
                  file,
                  length: duration,
                  uuid: (file.meta as any).uuid,
                  progress: 0,
                  done: false,
                  language: 'en-US',
                  numSpeaker: 1,
                  exceededDuration: maxFileDuration ? duration > maxFileDuration : false,
                },
              ])
            );
            if (uploadRef.current) {
              clearTimeout(uploadRef.current);
            }
            uploadRef.current = setTimeout(() => uppy.upload(), 500);
          }
        })
        .catch(() => {
          uppy.info(
            'We were unable to parse your file. Please retry or select another file',
            'error'
          );
          uppy.removeFile(file.id);
        });
    },
    [uppy]
  );

  function uploadProgress(file: UppyFile, progress: UploadProgress) {
    setUploadFiles((files) =>
      files.map((f) => {
        if (f.file.id === file.id) {
          return {
            ...f,
            progress: (progress.bytesUploaded / progress.bytesTotal) * 100,
          };
        }
        return f;
      })
    );
  }

  function uploadSuccess(file: UppyFile) {
    setUploadFiles((files) =>
      files.map((f) => {
        if (f.file.id === file.id) {
          return {
            ...f,
            progress: 100,
            done: true,
          };
        }
        return f;
      })
    );

    setSelectedFiles((files) => files.concat([(file.meta as any).uuid]));
  }

  function uploadError(file, error, response) {
    Sentry.captureException(error);
    setUploadFiles((files) =>
      files.map((f) => {
        if (f.file.id === file.id) {
          return {
            ...f,
            progress: 100,
            done: true,
            error: true,
          };
        }
        return f;
      })
    );
  }

  const createOnChangeEnableHandler = (uuid: string | undefined) => {
    return () => {
      if (uuid) {
        setSelectedFiles((files) => {
          if (files.includes(uuid)) {
            return files.filter((f) => f !== uuid);
          }
          return files.concat([uuid]);
        });
      }
    };
  };

  const createOnChangeLanguageHandler = (uuid: string | undefined) => {
    return (value: any) => {
      if (uuid) {
        setUploadFiles((files) =>
          files.map((file) => {
            if (file.uuid === uuid) {
              return { ...file, language: value };
            }
            return file;
          })
        );
      }
    };
  };

  const createOnChangeNumSpeakerHandler = (uuid: string | undefined) => {
    return (value: any) => {
      if (uuid) {
        setUploadFiles((files) =>
          files.map((file) => {
            if (file.uuid === uuid) {
              return { ...file, numSpeaker: value };
            }
            return file;
          })
        );
      }
    };
  };

  const handleSubmit = () => {
    const files = selectedFiles
      .map((uuid) => {
        const file = uploadFiles.find((f) => f.uuid === uuid);
        if (!file) {
          return null;
        }
        return {
          uuid,
          language: file.language,
          maxSpeakerCount: file.numSpeaker,
        };
      })
      .filter((f) => f !== null) as { uuid: string; language: string; maxSpeakerCount: number }[];
    onSubmit(files);
  };

  const total = useMemo(() => {
    return sum(
      uploadFiles
        .filter((f) => selectedFiles.includes(f.uuid))
        .filter((f) => !f.error && !f.exceededDuration)
        .map((f) => Math.max(f.length, 0))
    );
  }, [selectedFiles, uploadFiles]);

  const outOfBalance = useMemo(() => {
    return total > balance;
  }, [total, balance]);

  const disableSubmit = useMemo(() => {
    const notReady = uploadFiles.some((f) => !f.done);
    return notReady || outOfBalance;
  }, [uploadFiles, outOfBalance]);

  useEffect(() => {
    uppy.on('file-added', fileAdded);
    uppy.on('upload-progress', uploadProgress);
    uppy.on('upload-success', uploadSuccess);
    uppy.on('upload-error', uploadError);

    return () => {
      uppy.off('upload-error', uploadError);
      uppy.off('upload-success', uploadSuccess);
      uppy.off('upload-progress', uploadProgress);
      uppy.off('file-added', fileAdded);
    };
  }, [uppy, fileAdded]);

  return (
    <div className="upload-progress-container">
      <div className="upload-progress-top">
        <Typography.Title level={4}>Total: {formatLength(total)}</Typography.Title>

        <Button disabled={disableSubmit || submitting} onClick={handleSubmit} loading={submitting}>
          Submit
        </Button>
      </div>
      {outOfBalance && (
        <div className="out-of-balance">
          Your remaining balance is not sufficient, please{' '}
          <a href="/purchase" target="_blank">
            purchase more
          </a>{' '}
          before proceeding.
        </div>
      )}
      <div className="upload-progress-content">
        {uploadFiles.length === 0 && (
          <Empty description="No upload yet" style={{ padding: '16px 0' }} />
        )}
        {uploadFiles.map((file) => {
          let length: number | null = null;
          if (file.done && !file.error) {
            length = file.length;
          }

          return (
            <div className="upload-progress-item" key={file.file.id}>
              <div className="upload-progress-item-info">
                <div className="upload-progress-item-info-details">
                  <div className="upload-progress-item-info-checkbox">
                    <Checkbox
                      defaultChecked
                      disabled={
                        length === null ||
                        length < 0 ||
                        file.error ||
                        file.exceededDuration ||
                        submitting
                      }
                      checked={selectedFiles.includes(file.uuid)}
                      onChange={createOnChangeEnableHandler(file.uuid)}
                    />
                  </div>
                  <div
                    className={clsx({
                      'upload-progress-item-info-name': true,
                      'upload-error': file.error || file.exceededDuration,
                    })}
                  >
                    <div style={{ lineHeight: '30px' }}>{file.file.name}</div>
                    {file.error ? (
                      <div>An error has occurred. Please retry later.</div>
                    ) : file.exceededDuration ? (
                      <div>
                        File exceeded our length limit ({formatLength(maxFileDuration || 0)}).
                        Please choose another file.
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="upload-progress-item-info-length"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {length === null ? null : length < 0 ? (
                      <Spin />
                    ) : (
                      <div style={{ textAlign: 'left' }}>{formatLength(length)}</div>
                    )}
                  </div>
                </div>
                <div className="upload-progress-item-progress">
                  <Progress
                    percent={file.progress}
                    status={file.done ? (file.error ? 'exception' : undefined) : 'active'}
                    showInfo={false}
                  />
                </div>
              </div>

              <div className="upload-progress-item-language">
                <div style={{ fontWeight: 'bold' }}>Language</div>
                <Select
                  value={file.language}
                  onChange={createOnChangeLanguageHandler(file.uuid)}
                  style={{ width: 240 }}
                >
                  {Object.keys(LANGUAGES).map((lang) => (
                    <Select.Option key={lang} value={lang}>
                      {LANGUAGES[lang]}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="upload-progress-item-num-speakers">
                <div style={{ fontWeight: 'bold' }}>Max. no. of speakers</div>
                <Select
                  value={file.numSpeaker}
                  onChange={createOnChangeNumSpeakerHandler(file.uuid)}
                  style={{ width: 80 }}
                >
                  {range(1, MAX_NUMBER_OF_SPEAKERS + 1).map((num) => (
                    <Select.Option key={num} value={num}>
                      {num}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadProgress;
