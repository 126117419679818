import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Redirect, useHistory } from 'react-router-dom';

import { FileContentFragmentFragment } from '@/graphql/generated/operations';
import {
  useGetFileContentQuery,
  useCreateFolderMutation,
  GetFileContentDocument,
} from '@/graphql/generated/hooks';

import FileTable from '@/common/component/FileTable';

import Breadcrumb from './component/Breadcrumb';
import NewButton from './component/NewButton';
import NewFolderModal from './component/NewFolderModal';

const TAKE = 25;

interface Props {
  folderId?: number;
}
const Top = (props: Props) => {
  const { folderId } = props;

  const history = useHistory();

  const { error, data, fetchMore, networkStatus, startPolling, stopPolling } =
    useGetFileContentQuery({
      variables: { id: folderId, skip: 0, take: TAKE },
      notifyOnNetworkStatusChange: true,
    });
  const [createFolder] = useCreateFolderMutation({
    refetchQueries: () => {
      return [
        {
          query: GetFileContentDocument,
          variables: { id: folderId, skip: 0, take: (data?.fileChildren.items.length ?? 0) + 1 },
        },
      ];
    },
  });

  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);

  const handleLoadMore = useCallback(() => {
    return fetchMore({
      variables: {
        skip: data?.fileChildren.items.length,
        take: TAKE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({}, prev, {
          fileChildren: {
            items: [...prev.fileChildren.items, ...fetchMoreResult.fileChildren.items],
            total: fetchMoreResult.fileChildren.total,
          },
        });
      },
    });
  }, [data, fetchMore]);

  const handleNewFolder = useCallback(() => {
    setNewFolderModalOpen(true);
  }, []);

  const handleCloseNewFolderModal = useCallback(() => {
    setNewFolderModalOpen(false);
  }, []);

  const handleNewFolderSubmit = useCallback(
    (name: string) => {
      return createFolder({
        variables: {
          data: { name: name, parentId: folderId },
        },
      });
    },
    [createFolder, folderId]
  );

  const handleNewUpload = useCallback(() => {
    history.push(folderId ? `/upload?f=${folderId}` : '/upload');
  }, [history, folderId]);

  const folders = useMemo(() => {
    if (!data || !data.file) {
      return [];
    }
    let folder = data.file;
    let parents = [
      {
        id: folder.id,
        name: folder.name,
      },
    ];
    while (folder.parent) {
      folder = folder.parent as any;
      parents = [{ id: folder.id, name: folder.name }].concat(parents);
    }
    return parents;
  }, [data]);

  useEffect(() => {
    startPolling(5000);

    return () => stopPolling();
  }, [folderId, startPolling, stopPolling]);

  // TODO: Handle error
  if (error) {
    return <div>{`Error! ${error}`}</div>;
  }

  if (data && data.file && !data.file.isFolder) {
    // TODO: Show file details later
    return <Redirect to="/" />;
  }

  return (
    <div className="home-root">
      <div className="home-top-section">
        <Breadcrumb level={data?.file?.level ?? 0} folders={folders} />

        <NewButton onNewFolder={handleNewFolder} onNewUpload={handleNewUpload} />
      </div>

      <FileTable<FileContentFragmentFragment>
        data={data?.fileChildren?.items ?? []}
        total={data?.fileChildren?.total ?? 0}
        loading={networkStatus === NetworkStatus.loading}
        loadMore={handleLoadMore}
        loadingMore={networkStatus === NetworkStatus.fetchMore}
      />

      <NewFolderModal
        visible={newFolderModalOpen}
        onClose={handleCloseNewFolderModal}
        onSubmit={handleNewFolderSubmit}
      />
    </div>
  );
};

export default React.memo(Top);
