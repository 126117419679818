import React from 'react';
import { Breadcrumb as BreadcrumbComponent } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface File {
  id: string;
  name: string;
}
interface Props {
  folders: File[];
  level: number;
}
const Breadcrumb = (props: Props) => {
  const { folders, level } = props;
  return (
    <BreadcrumbComponent separator=">">
      <BreadcrumbComponent.Item>
        <Link to="/">
          <HomeOutlined />
          &nbsp; <FormattedMessage description="Top breadcrumb text" defaultMessage="Top" />
        </Link>
      </BreadcrumbComponent.Item>
      {level > 2 && <BreadcrumbComponent.Item>...</BreadcrumbComponent.Item>}
      {folders.map((f) => (
        <BreadcrumbComponent.Item key={f.id}>
          <Link to={`/f/${f.id}`}>{f.name}</Link>
        </BreadcrumbComponent.Item>
      ))}
    </BreadcrumbComponent>
  );
};

export default Breadcrumb;
