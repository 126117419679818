import React, { useCallback, memo } from 'react';

import { formatTimestamp } from '@/common/helper/time';

import './AudioPlayer.less';

interface Props {
  pos: number;
  duration: number;
  onSeek: (pos: number) => void;
}
const AudioPlayer = (props: Props) => {
  const { pos, duration, onSeek } = props;

  const percent = (pos / duration) * 100;

  const handleSeek = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const pos = (x / rect.width) * duration;
      onSeek(pos);
    },
    [duration, onSeek]
  );

  return (
    <div className="audio-player-root">
      <div className="current-pos">{formatTimestamp(pos.toString(), true)}</div>

      <div className="duration">{formatTimestamp(duration.toString(), true)}</div>

      <div className="seek-bar-container">
        <div className="seek-bar-overlay" onClick={handleSeek} />
        <div className="seek-bar-progress" style={{ width: `${percent}%` }} />
        <div className="seek-bar-handle" style={{ left: `${percent}%` }} />
      </div>
    </div>
  );
};

export default memo(AudioPlayer);
