import React, { useState, useCallback } from 'react';
import { Button, Typography, Row, Col, Modal, notification } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined, MailOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useAccount } from '@/common/provider/account';

import { useCancelSubscriptionMutation } from '@/graphql/generated/hooks';

import './PlanView.less';

const PremiumPlanView = () => {
  const { subscriptionStatus, nextPayment } = useAccount();

  const [cancelSubscription, { loading: cancelling }] = useCancelSubscriptionMutation();

  const [showCancelModal, setShowCancelModal] = useState(false);

  const hideModal = useCallback(() => setShowCancelModal(false), []);
  const showModal = useCallback(() => setShowCancelModal(true), []);
  const handleCancelSubscription = useCallback(() => {
    setShowCancelModal(false);
    cancelSubscription()
      .then(() => {
        notification.success({
          message: 'Subscription cancellation',
          description: 'Your subscription has been cancelled.',
        });
        setShowCancelModal(false);
      })
      .catch(() => {
        notification.error({
          message: 'Subscription cancellation',
          description:
            'Some error has occurred while cancelling your subscription. Please try again or contact our admins.',
        });
      });
  }, [cancelSubscription]);

  return (
    <div className="package-list-root">
      <Row>
        <Col xs={24}>
          <Typography.Title level={3}>Your subscription</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>You are subscribed to Premium plan</Typography.Title>
            <div className="subtext">&nbsp;</div>

            <Typography.Paragraph className="row description">
              {subscriptionStatus === 'ACTIVE' ? (
                <>Your next payment is on {moment(nextPayment).format('MMM DD, YYYY')}</>
              ) : (
                <>
                  You have cancelled your subscription, which will expire on{' '}
                  {moment(nextPayment).format('MMM DD, YYYY')}
                </>
              )}
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              You can purchase more hours for $ {process.env.REACT_APP_PREMIUM_HOUR_PRICE} / hour
            </Typography.Paragraph>

            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to="/purchase">
                <Button>
                  <ShoppingCartOutlined /> Buy hours
                </Button>
              </Link>

              {subscriptionStatus === 'ACTIVE' && (
                <Button danger onClick={showModal}>
                  <CloseOutlined /> Cancel subscription
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Enterprise</Typography.Title>
            <div className="subtext">Subscription</div>

            <Typography.Paragraph className="row description">
              High volume transcription with more specific needs.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              PLEASE CONTACT OUR TEAM
            </Typography.Paragraph>

            <div className="row">
              <a href="mailto:ellen@izitext.io">
                <Button>
                  <MailOutlined /> Contact us
                </Button>
              </a>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        title="Cancel subscription"
        visible={showCancelModal}
        onOk={handleCancelSubscription}
        onCancel={hideModal}
        okButtonProps={{ danger: true, loading: cancelling }}
        cancelButtonProps={{ disabled: cancelling }}
        okText="Yes, cancel my subscription"
        cancelText="No"
      >
        <p>Do you really want to cancel your subscription?</p>
        {nextPayment && (
          <p>
            Your subscription will still be active until{' '}
            {moment(nextPayment).format('MMM DD, YYYY')} after cancellation.
          </p>
        )}
      </Modal>
    </div>
  );
};

export default PremiumPlanView;
