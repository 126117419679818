import React from 'react';
import { Button, Typography, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined, MailOutlined } from '@ant-design/icons';

import './PlanView.less';

const LifetimePlanView = () => {
  return (
    <div className="package-list-root">
      <Row>
        <Col xs={24}>
          <Typography.Title level={3}>Your plan</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Lifetime</Typography.Title>
            <div className="subtext">ACTIVE</div>

            <Typography.Paragraph className="row description">
              Enjoy a better hour pricing for a lifetime.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              You can purchase more hours for $ {process.env.REACT_APP_LIFETIME_HOUR_PRICE} / hour
            </Typography.Paragraph>

            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to="/purchase">
                <Button>
                  <ShoppingCartOutlined /> Buy hours
                </Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="package-container">
            <Typography.Title level={4}>Enterprise</Typography.Title>
            <div className="subtext">Subscription</div>

            <Typography.Paragraph className="row description">
              High volume transcription with more specific needs.
            </Typography.Paragraph>

            <Typography.Paragraph className="row pricing">
              PLEASE CONTACT OUR TEAM
            </Typography.Paragraph>

            <div className="row">
              <a href="mailto:ellen@izitext.io">
                <Button>
                  <MailOutlined /> Contact us
                </Button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LifetimePlanView;
