import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useGetLifetimeLinkLazyQuery } from '@/graphql/generated/hooks';

interface Options {
  checkoutSuccessCallback: () => void;
}

export const useLifetime = ({ checkoutSuccessCallback }: Options) => {
  const [getLifetimeLink, { data: lifetimeLinkData, loading: lifetimeLinkLoading }] =
    useGetLifetimeLinkLazyQuery();

  const [pid] = useState<string>(() => uuidv4());

  const handleCheckout = useCallback(() => {
    getLifetimeLink({
      variables: {
        pid,
      },
    });
  }, [getLifetimeLink, pid]);

  const triggerCheckout = useCallback(
    (url) => {
      function checkoutComplete() {
        checkoutSuccessCallback();
      }

      (window as any).Paddle.Checkout.open({
        override: url,
        successCallback: checkoutComplete,
      });
    },
    [checkoutSuccessCallback]
  );

  useEffect(() => {
    if (!(window as any).Paddle) {
      const script = document.createElement('script');
      script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
      script.onload = function initPaddle() {
        (window as any).Paddle.Setup({
          vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || '', 10),
        });
      };
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (lifetimeLinkData) {
      triggerCheckout(lifetimeLinkData.lifetimeLink);
    }
  }, [lifetimeLinkData, triggerCheckout]);

  return { handleCheckout, lifetimeLinkLoading };
};
