/* istanbul ignore file */

import React, { useCallback, useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Input, Typography, Button, notification } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import * as Sentry from '@sentry/react';

import { useFirebaseApp } from '@/common/provider/firebase';
import { useAuthState } from '@/common/provider/auth';

import {
  useCreateAccountIfNotExistMutation,
  useRedeemLifetimeCouponMutation,
} from '@/graphql/generated/hooks';

import LoginImage from './login-image.png';

import './AppSumo.less';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google',
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Facebook',
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Email',
    },
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const AppSumoLogin = () => {
  const firebaseApp = useFirebaseApp();
  const { user, authenticating } = useAuthState();
  const [createAccountIfNotExist] = useCreateAccountIfNotExistMutation();
  const [redeemLifetimeCoupon] = useRedeemLifetimeCouponMutation();

  const params = useParams<{ coupon?: string }>();
  const history = useHistory();

  const [coupon, setCoupon] = useState((params.coupon || '').toUpperCase());
  const [redeemed, setRedeemed] = useState(false);
  const [redeemError, setRedeemError] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const handleRedeemAgain = useCallback(() => {
    if (!coupon) {
      return;
    }
    setRedeemed(false);
    setDisableInput(true);
  }, [coupon]);

  useEffect(() => {
    if (!authenticating && user && !redeemed) {
      createAccountIfNotExist()
        .then((res) => {
          if (coupon) {
            if (res.data?.createAccountIfNotExist.plan === 'LIFETIME') {
              notification.warning({
                message: 'Lifetime package',
                description: 'You have already activated your lifetime package.',
              });
              history.push('/');
              return;
            }
            redeemLifetimeCoupon({ variables: { coupon } })
              .then((res) => {
                setDisableInput(false);
                setRedeemed(true);
                if (res.data?.redeemLifetimeCoupon) {
                  setRedeemError(false);
                  history.push('/');
                  notification.success({
                    message: 'Lifetime package activated',
                    description: 'You have successfully redeemed your lifetime package.',
                  });
                } else {
                  setRedeemError(true);
                }
              })
              .catch((e) => {
                Sentry.captureException(e);
                setRedeemed(true);
                setRedeemError(true);
              });
          } else {
            history.push('/');
          }
        })
        .catch((e) => {
          // TODO: Deal with error
          Sentry.captureException(e);
          history.push('/appsumo');
        });
    }
  }, [
    authenticating,
    user,
    coupon,
    history,
    redeemed,
    createAccountIfNotExist,
    redeemLifetimeCoupon,
  ]);

  useEffect(() => {
    function onClick() {
      setDisableInput(true);
    }

    function setup() {
      const buttons = window.document.querySelectorAll('.firebaseui-idp-button');
      if (buttons.length === 0) {
        setTimeout(setup, 500);
        return;
      }
      for (const button of buttons) {
        button.addEventListener('click', onClick);
      }
    }

    setup();

    return () => {
      const buttons = window.document.querySelectorAll('.firebaseui-idp-button');
      for (const button of buttons) {
        button.removeEventListener('click', onClick);
      }
    };
  }, []);

  if (authenticating) {
    return null;
  }

  return (
    <div className="appsumo-root">
      <div className="appsumo-box">
        <Typography.Title level={2}>Hey Sumo-lings!</Typography.Title>

        <Typography.Paragraph style={{ width: 217, margin: '10px 0' }}>
          Please share your AppSumo code and login to get enrolled in izitext's for AppSumo with{' '}
          <strong>lifetime access</strong>.
        </Typography.Paragraph>

        <Typography.Paragraph style={{ width: 217, margin: '10px 0' }}>
          Thanks so much and happy to have you!
        </Typography.Paragraph>

        <Input
          type="text"
          placeholder="AppSumo code"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value ? e.target.value.toUpperCase() : '')}
          style={{ width: 217, margin: '15px 0' }}
          disabled={disableInput}
        />

        {redeemed && redeemError && (
          <Typography.Paragraph style={{ width: 217, margin: '10px 0', color: 'red' }}>
            We're unable to redeem your coupon. Please verify to ensure your coupon is correct, or
            contact us at <a href="mailto:support@izitext.io">support@izitext.io</a>.
          </Typography.Paragraph>
        )}

        {redeemed && redeemError && (
          <Button type="primary" onClick={handleRedeemAgain}>
            Redeem again
          </Button>
        )}

        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth()} />
      </div>
      <div className="appsumo-image">
        <img src={LoginImage} alt="AppSumo" />
      </div>
    </div>
  );
};

export default AppSumoLogin;
