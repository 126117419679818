/* istanbul ignore file */

import React from 'react';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';

import firebase from 'firebase';

import { useFirebaseApp } from './firebase';

interface AuthState {
  authenticating: boolean;
  user: firebase.User | null;
  idToken: string | null;
}

export interface AuthContextValue extends AuthState {
  logout: () => void;
}

const initialValue: AuthContextValue = {
  authenticating: true,
  user: null,
  idToken: null,
  logout: () => {},
};

const AuthContext = React.createContext<AuthContextValue>(initialValue);

export const ID_TOKEN_KEY = 'id-token';

enum ACTION_TYPE {
  AUTH_STATE_CHANGED,
  LOGIN,
  LOGOUT,
}

interface IAction {
  type: ACTION_TYPE;
}

interface LoginAction extends IAction {
  type: ACTION_TYPE.LOGIN;
  payload: {
    user: firebase.User | null;
    idToken: string;
  };
}

interface LogoutAction extends IAction {
  type: ACTION_TYPE.LOGOUT;
}

type Action = LoginAction | LogoutAction;

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ACTION_TYPE.LOGIN:
      return {
        ...state,
        ...action.payload,
        authenticating: false,
      };
    case ACTION_TYPE.LOGOUT:
      return {
        authenticating: false,
        user: null,
        idToken: null,
      };
    default:
      return state;
  }
};

const AuthProvider = (props: { [key: string]: unknown }) => {
  const firebaseApp = useFirebaseApp();
  const client = useApolloClient();

  const [state, dispatch] = React.useReducer(reducer, initialValue);

  const logout = React.useCallback(() => {
    // Clearing token from localStorage
    localStorage.clear();
    // Clearing user from Apollo store
    client.clearStore().then(() => firebaseApp.auth().signOut());
    // Clearing user from sentry
    Sentry.withScope((scope) => scope.setUser(null));
  }, [firebaseApp, client]);

  const value = React.useMemo(() => {
    return {
      ...state,
      logout,
    };
  }, [state, logout]);

  React.useEffect(() => {
    const observer = firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          dispatch({ type: ACTION_TYPE.LOGIN, payload: { user, idToken: token } });

          Sentry.setUser({ id: user.uid, email: user.email || undefined });

          localStorage.setItem(ID_TOKEN_KEY, token);
        });
      } else {
        dispatch({ type: ACTION_TYPE.LOGOUT });
      }
    });

    return () => observer();
  }, [dispatch, firebaseApp]);

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuthState = () => {
  const authState = React.useContext(AuthContext);

  return authState;
};

const useAuthenticating = () => {
  const { authenticating } = React.useContext(AuthContext);

  return authenticating;
};

const useIdToken = () => {
  const { idToken } = React.useContext(AuthContext);

  return idToken;
};

const useUser = () => {
  const { user } = React.useContext(AuthContext);

  return user;
};

const useLogout = () => {
  const { logout } = React.useContext(AuthContext);

  return logout;
};

export {
  AuthContext,
  AuthProvider,
  useAuthenticating,
  useAuthState,
  useIdToken,
  useLogout,
  useUser,
};
