import React from 'react';
import { Form, Switch, Select } from 'antd';

interface Props {
  forms: ReturnType<typeof Form.useForm>;
}
const TxtExportOption = (props: Props) => {
  const { forms } = props;
  const [form] = forms;
  return (
    <>
      <label>Timecode options</label>
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>
            <Form.Item
              name={['txtOptions', 'includeTimecodeParagraph']}
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            <span style={{ marginLeft: 10 }}>Include timecodes to every paragraph</span>
          </label>
        </div>
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item name={['txtOptions', 'includeTimecodeMs']} valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
          <label style={{ marginLeft: 10 }}>Include milliseconds with timecode</label>
        </div>
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item
            name={['txtOptions', 'includeTimecodeEveryPeriod']}
            valuePropName="checked"
            noStyle
          >
            <Switch />
          </Form.Item>
          <label style={{ marginLeft: 10 }}>Include timecodes every</label>
          <Form.Item shouldUpdate noStyle>
            {() =>
              form.getFieldValue(['txtOptions', 'includeTimecodeEveryPeriod']) && (
                <Form.Item name={['txtOptions', 'timecodePeriod']} noStyle>
                  <Select style={{ width: 150, marginLeft: 10 }} placeholder="Please select">
                    <Select.Option value={5}>5 seconds</Select.Option>
                    <Select.Option value={10}>10 seconds</Select.Option>
                    <Select.Option value={15}>15 seconds</Select.Option>
                    <Select.Option value={20}>20 seconds</Select.Option>
                    <Select.Option value={30}>30 seconds</Select.Option>
                    <Select.Option value={60}>60 seconds</Select.Option>
                    <Select.Option value={120}>2 minutes</Select.Option>
                    <Select.Option value={300}>5 minutes</Select.Option>
                    <Select.Option value={600}>10 minutes</Select.Option>
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>
        </div>
      </Form.Item>

      <label>Extra options</label>
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item name={['txtOptions', 'includeSpeakerName']} valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
          <label style={{ marginLeft: 10 }}>Include speaker names</label>
        </div>
      </Form.Item>
    </>
  );
};

export default TxtExportOption;
