import React from 'react';
import { Dropdown } from 'antd';
import clsx from 'clsx';

import './index.less';

const HeaderDropdown = ({ overlayClassName: cls = '', overlay, ...restProps }) => (
  <Dropdown
    overlayClassName={clsx('dropdown-overlay-container', cls)}
    overlay={overlay}
    {...restProps}
  />
);

export default HeaderDropdown;
