import React from 'react';
import { Typography } from 'antd';

const Footer = () => {
  return (
    <Typography.Paragraph style={{ textAlign: 'center' }}>
      izitext.io &copy; 2020
    </Typography.Paragraph>
  );
};

export default Footer;
