import React from 'react';
import { Form, Switch, Select, Slider, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  forms: ReturnType<typeof Form.useForm>;
}
const SrtExportOption = (props: Props) => {
  const { forms } = props;
  const [form] = forms;
  return (
    <>
      <Form.Item
        label="Do you want to show speaker names?"
        name={['srtOptions', 'showSpeakerName']}
      >
        <Select style={{ width: '100%' }} placeholder="Please select">
          <Select.Option value="NO">No</Select.Option>
          <Select.Option value="AS_IS">Yes, show speaker names (as is)</Select.Option>
          <Select.Option value="UPPER">Yes, show speaker names (UPPERCASE)</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Do you want to split your subtitles?" name={['srtOptions', 'split']}>
        <Select style={{ width: '100%' }} placeholder="Please select">
          <Select.Option value={1}>Keep it as a single line</Select.Option>
          <Select.Option value={2}>Split over 2 lines</Select.Option>
          <Select.Option value={3}>Split over 3 lines</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <span>
            Max character length for each subtitle{' '}
            <Tooltip
              placement="right"
              title="If the number of characters is greater than this limit, the text will be split to another subtitle."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        shouldUpdate
      >
        {() => (
          <div style={{ paddingRight: 55 }}>
            <Tooltip
              visible={true}
              placement="right"
              title={form.getFieldValue(['srtOptions', 'maxLength'])}
              overlayStyle={{ width: 55 }}
            >
              <Form.Item name={['srtOptions', 'maxLength']} noStyle>
                <Slider min={30} max={140} />
              </Form.Item>
            </Tooltip>
          </div>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            Max duration for each subtitle{' '}
            <Tooltip
              placement="right"
              title="If the duration is longer than this limit, the text will be split to another subtitle. To not enforce this limit, choose 10s"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        shouldUpdate
      >
        {() => (
          <div style={{ paddingRight: 55 }}>
            <Tooltip
              visible={true}
              placement="right"
              title={(form.getFieldValue(['srtOptions', 'maxDuration']) || '0') + 's'}
              overlayStyle={{ width: 55 }}
            >
              <Form.Item name={['srtOptions', 'maxDuration']} noStyle>
                <Slider min={0.4} max={10.1} step={0.1} />
              </Form.Item>
            </Tooltip>
          </div>
        )}
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item name={['srtOptions', 'breakOverride']} valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
          <label style={{ marginLeft: 10 }}>
            Paragraph breaks override limits set above{' '}
            <Tooltip
              placement="right"
              title="The paragraph breaks in the transcript override when text moves to the next subtitle."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </label>
        </div>
      </Form.Item>
    </>
  );
};

export default SrtExportOption;
