/* istanbul ignore file */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../Home';
import Upload from '../Upload';
import Purchase from '../Purchase';
import Subscribe from '../Purchase/Subscribe';
import YourPlan from '../Purchase/YourPlan';
import Transcription from '../Transcription';
import Recent from '../Recent';
import Editor from '../Editor';

const Routes = () => {
  return (
    <Switch>
      <Route path="/upload" component={Upload} />
      <Route path="/plan" component={YourPlan} />
      <Route path="/purchase" component={Purchase} />
      <Route path="/subscribe" component={Subscribe} />
      <Route path="/recent" component={Recent} />
      <Route path="/t/:fileId" component={Editor} />
      <Route path="/f/:fileId/transcription" component={Transcription} />
      <Route path="/f/:folderId" component={Home} />
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default Routes;
