import React, { useMemo, useState } from 'react';
import { Drawer } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useAntdMediaQuery from 'use-media-antd-query';

import LogoWhite from '../../image/logo-white.png';

import MainMenu from '../SideMenu/MainMenu';

import AvatarDropdown from './AvatarDropdown';
import SelectLanguage from './SelectLanguage';

import './index.less';

const GlobalHeader = () => {
  const screenSize = useAntdMediaQuery();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const menuToggle = useMemo(() => {
    if (screenSize === 'sm' || screenSize === 'xs') {
      return (
        <>
          <span
            className="global-header-toggle-menu"
            onClick={() => setDrawerOpen((o) => !o)}
            data-testid="btn-menu-toggle"
          >
            {drawerOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </span>
          <Drawer
            placement="left"
            onClose={() => setDrawerOpen(false)}
            visible={drawerOpen}
            closeIcon={null}
            width={200}
            bodyStyle={{ padding: 0, paddingTop: 48 }}
          >
            <MainMenu collapsed={false} />
          </Drawer>
        </>
      );
    }
    return null;
  }, [screenSize, drawerOpen]);

  return (
    <div className="global-header">
      <span className="global-header-logo">
        <Link to="/">
          <img src={LogoWhite} alt="izitext.io" />
        </Link>
      </span>
      {menuToggle}
      <div style={{ flex: '1 1 0%' }} />
      <div className="global-header-right">
        <AvatarDropdown />
        <SelectLanguage />
      </div>
    </div>
  );
};

export default GlobalHeader;
