import React, { useCallback, useContext, useEffect, useMemo, useState, memo } from 'react';
import { ClockCircleFilled } from '@ant-design/icons';
import { Popover, Input, Tooltip } from 'antd';

import { formatTimestamp } from '@/common/helper/time';

import { EditorContext } from '../EditorContainer';

import './TimestampEditor.less';

interface Timestamp {
  start: number;
  end: number;
}
interface Props {
  element: any;
}
const TimestampEditor = (props: Props) => {
  const { element } = props;
  const { prevEnd, nextStart } = element;
  const current = { start: element.start_time, end: element.end_time };

  const editor = useContext(EditorContext);

  const [show, setShow] = useState(false);
  const [value, setValue] = useState(current);
  const [pendingValue, setPendingValue] = useState(current);
  const [error, setError] = useState<string | null>(null);

  const onChange = useCallback(
    (value: Timestamp) => {
      editor.editTimestamps(element.id, value.start, value.end);
    },
    [element, editor]
  );

  const handleVisibleChange = useCallback(
    (visible) => {
      if (!visible) {
        onChange(value);
      }
      setShow(visible);
    },
    [onChange, value]
  );

  const validate = useCallback(
    (value: number) => {
      let result: string | null = null;
      if (prevEnd) {
        if (value < prevEnd) {
          result = 'This timestamp overlaps with the previous one.';
        }
      } else if (value < 0) {
        result = 'Timestamp cannot be negative.';
      }
      if (!result) {
        if (nextStart && value > nextStart) {
          result = 'This timestamp overlaps with the next one.';
        }
      }
      return result;
    },
    [prevEnd, nextStart]
  );

  const content = useMemo(
    () => (
      <div className="timestamp-editor-content">
        <div className="timestamp-editor-label">
          <h3>Adjust timestamps</h3>

          {error && (
            <Tooltip placement="right" title={error}>
              <span className="timestamp-error">Invalid timestamp</span>
            </Tooltip>
          )}
        </div>

        <TimestampEditorBox
          label="Start timestamp"
          time={pendingValue.start}
          validate={validate}
          onChange={(value: number) => setPendingValue((v) => ({ ...v, start: value }))}
        />

        <TimestampEditorBox
          label="End timestamp"
          time={pendingValue.end}
          validate={validate}
          onChange={(value: number) => setPendingValue((v) => ({ ...v, end: value }))}
        />
      </div>
    ),
    [pendingValue, validate, error]
  );

  useEffect(() => {
    if (pendingValue.start === value.start && pendingValue.end === value.end) {
      setError(null);
      return;
    }
    if (pendingValue.start >= 0 && pendingValue.start <= pendingValue.end) {
      setValue(pendingValue);
      setError(null);
    } else {
      setError('Invalid timestamps. Please review the timestamps again.');
    }
  }, [pendingValue, value]);

  return (
    <Popover
      content={content}
      trigger="click"
      visible={show}
      onVisibleChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <span className="timestamp-editor-display">
        <ClockCircleFilled /> {formatTimestamp(current.start.toString(), true)}
      </span>
    </Popover>
  );
};

interface EditorBoxProps {
  label: string;
  time: number;
  validate: (value: number) => string | null;
  onChange: (value: number) => void;
}
const TimestampEditorBox = (props: EditorBoxProps) => {
  const { label, time, validate, onChange } = props;

  const [pendingValue, setPendingValue] = useState(time);
  const [value, setValue] = useState(time);
  const [hours, setHours] = useState(Math.floor(time / 3600));
  const [minutes, setMinutes] = useState(Math.floor((time % 3600) / 60));
  const [seconds, setSeconds] = useState(Math.floor(time % 60));
  const [mseconds, setMseconds] = useState(Math.floor(time * 1000) % 1000);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const newValue = hours * 3600 + minutes * 60 + seconds + mseconds / 1000;
    setPendingValue(newValue);
  }, [hours, minutes, seconds, mseconds]);

  useEffect(() => {
    if (pendingValue === value) {
      setError(null);
      return;
    }
    const validation = validate(pendingValue);
    if (validation) {
      setError(validation);
    } else {
      setError(null);
      setValue(pendingValue);
      onChange(pendingValue);
    }
  }, [pendingValue, value, validate, onChange]);

  return (
    <>
      <div className="timestamp-editor-label">
        <h4>{label}</h4>

        {error && (
          <Tooltip placement="right" title={error}>
            <span className="timestamp-error">Invalid timestamp</span>
          </Tooltip>
        )}
      </div>
      <div className="timestamp-editor-box">
        <div>
          <label className="form-time-label" data-time-label="hr">
            <Input
              type="number"
              className="form-time-field form-time-hour"
              placeholder="00"
              min={0}
              max={99}
              value={hours}
              onChange={(p) => setHours(Math.floor(parseInt(p.target.value, 10)))}
            />
          </label>
        </div>
        <div>
          <label className="form-time-label" data-time-label="min" data-time-separator=":">
            <Input
              type="number"
              className="form-time-field form-time-minute"
              placeholder="00"
              min={0}
              max={60}
              value={minutes}
              onChange={(p) => setMinutes(Math.floor(parseInt(p.target.value, 10)))}
            />
          </label>
        </div>
        <div>
          <label className="form-time-label" data-time-label="sec" data-time-separator=":">
            <Input
              type="number"
              className="form-time-field form-time-second"
              placeholder="00"
              min={0}
              max={60}
              value={seconds}
              onChange={(p) => setSeconds(Math.floor(parseInt(p.target.value, 10)))}
            />
          </label>
        </div>
        <div>
          <label className="form-time-label" data-time-label="ms" data-time-separator=".">
            <Input
              type="number"
              className="form-time-field form-time-msecond"
              placeholder="000"
              min={0}
              max={999}
              value={mseconds}
              onChange={(p) => setMseconds(Math.floor(parseInt(p.target.value, 10)))}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default memo(TimestampEditor);
