/* istanbul ignore file */

import React, { useContext, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';

import enMessages from '../i18n/compiled-message/en.json';

const allMessages = {
  en: enMessages,
};

const I18N_CONFIG_KEY = 'i18nConfig';

interface I18nContextType {
  selectedLang: string;
}

const initialState: I18nContextType = {
  selectedLang: 'en',
};

const I18nContext = React.createContext<I18nContextType>(initialState);

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (err) {
      /* eslint-disable-next-line */
      console.error(err);
      Sentry.captureException(err);
    }
  }
  return initialState;
}

export function useLang() {
  return useContext(I18nContext).selectedLang;
}

export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

export function I18nProvider({ children }) {
  const lang = useMemo(getConfig, []);
  const messages = useMemo(() => allMessages[lang.selectedLang], [lang]);

  return (
    <I18nContext.Provider value={lang}>
      <IntlProvider locale={lang.selectedLang} messages={messages} defaultLocale="en">
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
}
