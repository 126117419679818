import { Node, Element, Text } from 'slate';

import { formatTimestamp } from '@/common/helper/time';

import { CONFIDENCE_THRESHOLD } from './constants';

interface Item {
  start_time?: number;
  end_time?: number;
  confidence: number;
  text: string;
}

interface Segment {
  start_time: number;
  end_time: number;
  speaker_label: string;
  children: Item[];
}

interface Input {
  segments: Segment[];
}

export interface OutputChild extends Text {
  id: number;
  start_time?: number;
  end_time?: number;
  confidence: number;
  text: string;
}

export interface OutputSegment extends Element {
  type: 'segment';
  start_time: number;
  end_time: number;
  speaker_label: string;
  children: OutputChild[];
}

export const transform = (input: Input) => {
  const results: Node[] = [];

  const { segments } = input;

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const { children, start_time } = segment;
    results.push({
      ...segment,
      id: results.length,
      type: 'segment',
      timestamp: `${formatTimestamp(start_time.toString(), true)}`,
      prevEnd: i > 0 ? segments[i - 1].end_time : null,
      nextStart: i < segments.length - 1 ? segments[i + 1].start_time : null,
      children: children.map((item, idx) => ({
        ...item,
        id: idx,
        confidenceClass:
          item.confidence >= CONFIDENCE_THRESHOLD.VERY
            ? 'very-confident'
            : item.confidence >= CONFIDENCE_THRESHOLD.FAIRLY
            ? 'fairly-confident'
            : 'slightly-confident',
      })),
    });
  }

  return results;
};
