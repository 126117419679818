import React, { useCallback, useContext, useMemo, useState, useRef, memo } from 'react';
import { Popover, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faStickyNote as fasStickyNote } from '@fortawesome/free-solid-svg-icons';

import { EditorContext } from '../EditorContainer';

import './NotePopup.less';

interface Props {
  element: any;
}
const NotePopup = (props: Props) => {
  const { element } = props;
  const { note } = element;

  const editor = useContext(EditorContext);

  const ref = useRef<any>();

  const [show, setShow] = useState(false);
  const [value, setValue] = useState<string>(note || '');

  const onChange = useCallback(
    (value: string) => {
      editor.setNote(element.id, value);
    },
    [element, editor]
  );

  const handleVisibleChange = useCallback(
    (visible) => {
      if (!visible) {
        onChange(value);
      }
      setShow(visible);
    },
    [onChange, value]
  );

  const content = useMemo(() => {
    return (
      <div className="segment-note-popup">
        <h3>Add note</h3>

        <Input.TextArea
          rows={4}
          autoSize={{ minRows: 4, maxRows: 10 }}
          defaultValue={value}
          onChange={(p) => setValue(p.target.value || '')}
        />
      </div>
    );
  }, [value]);

  return (
    <Popover
      content={content}
      trigger="click"
      visible={show}
      onVisibleChange={handleVisibleChange}
      placement="bottomRight"
      getPopupContainer={() => ref.current as any}
    >
      <span className="segment-note-button" ref={ref}>
        {note ? <FontAwesomeIcon icon={fasStickyNote} /> : <FontAwesomeIcon icon={faStickyNote} />}
      </span>
    </Popover>
  );
};

export default memo(NotePopup);
