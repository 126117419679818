import React, { useCallback, useMemo } from 'react';
import { Avatar, Menu, Spin } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useLogout, useAuthState } from '@/common/provider/auth';

import HeaderDropdown from '../HeaderDropdown';

const AvatarDropdown = () => {
  const { user } = useAuthState();
  const logout = useLogout();

  const onMenuClick = useCallback(
    (event) => {
      switch (event.key) {
        case 'logout':
          logout();
          break;
      }
    },
    [logout]
  );

  const menuHeaderDropdown = useMemo(
    () => (
      <Menu className="global-header-menu" selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key="logout" data-testid="btn-logout">
          <LogoutOutlined />
          <FormattedMessage description="Logout text" defaultMessage="Logout" />
        </Menu.Item>
      </Menu>
    ),
    [onMenuClick]
  );

  if (!user) {
    return (
      <span className="avatar-action avatar-account">
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );
  }

  return (
    <HeaderDropdown overlay={menuHeaderDropdown} placement="bottomLeft">
      <span className="avatar-action avatar-account" data-testid="btn-avatar">
        <Avatar size="small" className="avatar-avatar" alt="avatar" />
        <span className="avatar-name anticon">{user.displayName}</span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
