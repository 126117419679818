import React from 'react';
import { Redirect } from 'react-router-dom';
import useAntdMediaQuery from 'use-media-antd-query';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';

import { useAuthState } from '@/common/provider/auth';

import SplashScreen from './component/SplashScreen';

import App from './App';

function FallbackComponent() {
  return <div>An error has occured</div>;
}

const AppContainer = () => {
  const { user, authenticating } = useAuthState();
  const screenSize = useAntdMediaQuery();

  if (process.env.NODE_ENV === 'production') {
    // TODO: Style up the error boundary
    return (
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <StyledContainer className={`root screen-${screenSize}`}>
          {authenticating ? <SplashScreen /> : user ? <App /> : <Redirect to="/login" />}
        </StyledContainer>
      </Sentry.ErrorBoundary>
    );
  } else {
    return (
      <StyledContainer className={`root screen-${screenSize}`}>
        {authenticating ? <SplashScreen /> : user ? <App /> : <Redirect to="/login" />}
      </StyledContainer>
    );
  }
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

export default AppContainer;
