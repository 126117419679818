import React from 'react';
import { Typography } from 'antd';

import { useAccount } from '@/common/provider/account';

const PriceTable = () => {
  const { plan, isSubscriptionEffective } = useAccount();

  return (
    <>
      <Typography.Title level={4}>Price Guide</Typography.Title>

      <div className="row">
        <div className="hour">1 - 100 Hours</div>
        <div className="price">
          ${' '}
          {plan === 'LIFETIME'
            ? process.env.REACT_APP_LIFETIME_HOUR_PRICE
            : plan === 'STANDARD' || !isSubscriptionEffective
            ? process.env.REACT_APP_STANDARD_HOUR_PRICE
            : process.env.REACT_APP_PREMIUM_HOUR_PRICE}{' '}
          / Hour
        </div>
      </div>
    </>
  );
};

export default PriceTable;
