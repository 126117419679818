import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { useGetMeLazyQuery } from '@/graphql/generated/hooks';

import { useAuthState } from './auth';

interface AccountContextValue {
  balance: number;
  plan: string;
  isSubscriptionEffective: boolean;
  nextPayment?: Date;
  subscriptionStatus: string;
}

const initialValue: AccountContextValue = {
  balance: 0,
  plan: 'STANDARD',
  isSubscriptionEffective: false,
  nextPayment: undefined,
  subscriptionStatus: 'INACTIVE',
};

const AccountContext = createContext<AccountContextValue>(initialValue);

export const AccountProvider = (props) => {
  const [getMe, { data: meData }] = useGetMeLazyQuery({
    pollInterval: 5000,
  });

  const authState = useAuthState();

  const value = useMemo(() => {
    if (meData) {
      return {
        balance: meData.me.balance,
        plan: meData.me.plan,
        isSubscriptionEffective: meData.me.isSubscriptionEffective,
        nextPayment: meData.me.nextPayment,
        subscriptionStatus: meData.me.subscriptionStatus,
      };
    }
    return initialValue;
  }, [meData]);

  useEffect(() => {
    if (!authState.authenticating && authState.idToken) {
      getMe();
    }
  }, [getMe, authState]);

  return <AccountContext.Provider value={value}>{props.children}</AccountContext.Provider>;
};

export const useAccount = () => {
  const account = useContext(AccountContext);
  return account;
};
