import React, { useCallback, useContext, memo } from 'react';
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';

import { SoundContext } from '../EditorContainer';

interface Props {
  element: any;
}
const SegmentControl = (props: Props) => {
  const { element } = props;

  const { setPos, sound } = useContext(SoundContext);

  const handlePlayFrom = useCallback(() => {
    if (sound) {
      sound.seek(element.start_time);
      setPos(element.start_time);
    }
  }, [sound, setPos, element]);

  const handlePause = useCallback(() => {
    if (sound) {
      sound.pause();
    }
  }, [sound]);

  return (
    <>
      <PlayCircleFilled onClick={handlePlayFrom} />
      <PauseCircleFilled onClick={handlePause} />
    </>
  );
};

export default memo(SegmentControl);
