import React, { useCallback, useMemo } from 'react';
import { Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

import { useLang, setLanguage } from '@/common/provider/i18n';

import HeaderDropdown from '../HeaderDropdown';

import './SelectLanguage.less';

// TODO: Move to constants file
const locales = ['en'];
const labels = {
  en: 'English',
};
const icons = {
  en: '🇬🇧',
};

const SelectLanguage = () => {
  const selectedLang = useLang();

  const setLanguageHandler = useCallback(({ key }) => setLanguage(key), []);

  const langMenu = useMemo(
    () => (
      <Menu
        className="global-header-menu"
        selectedKeys={[selectedLang]}
        onClick={setLanguageHandler}
      >
        {locales.map((locale) => (
          <Menu.Item key={locale}>
            <span role="img" aria-label={labels[locale]} data-testid={`lang-${locale}`}>
              {icons[locale]}
            </span>{' '}
            {labels[locale]}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [setLanguageHandler, selectedLang]
  );

  return (
    <div
      style={{
        padding: '0 12px',
      }}
    >
      <HeaderDropdown
        overlay={langMenu}
        placement="bottomRight"
        overlayClassName="select-lang-overlay"
      >
        <span className="select-lang-dropdown" data-testid="btn-lang">
          <GlobalOutlined />
        </span>
      </HeaderDropdown>
    </div>
  );
};

export default SelectLanguage;
