import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export function useGA() {
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!, {
      debug: process.env.NODE_ENV === 'development',
      testMode: process.env.NODE_ENV === 'test',
    });
  }, []);
}

export function usePageView() {
  const location = useLocation();
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
}
