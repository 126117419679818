import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Spin, Row, Col } from 'antd';

import { useGetTranscriptionLazyQuery } from '@/graphql/generated/hooks';

import { formatTimestamp } from '@/common/helper/time';
import { useWindowSize } from '@/common/hook/window-size';

import Breadcrumb from '../Home/component/Breadcrumb';

import { transform } from './helper';
import './Transcription.less';

const Transcription = () => {
  const { fileId }: { fileId: any } = useParams();

  const { height } = useWindowSize();

  const [getTranscription, { data, loading }] = useGetTranscriptionLazyQuery();

  const segments = useMemo(() => {
    if (data && data.file && data.file.transcription) {
      return transform(JSON.parse(data.file.transcription.originDocument.finalContent)).segments;
    }
    return [];
  }, [data]);

  const showHours = useMemo(() => {
    if (data && data.file && data.file.transcription) {
      return data.file.transcription.length >= 3600;
    }
    return true;
  }, [data]);

  const folders = useMemo(() => {
    if (!data || !data.file) {
      return [];
    }
    let folder = data.file;
    let parents = [
      {
        id: folder.id,
        name: folder.name,
      },
    ];
    while (folder.parent) {
      folder = folder.parent as any;
      parents = [{ id: folder.id, name: folder.name }].concat(parents);
    }
    return parents;
  }, [data]);

  useEffect(() => {
    if (fileId && !isNaN(parseInt(fileId, 10))) {
      getTranscription({ variables: { id: parseInt(fileId, 10) } });
    }
  }, [getTranscription, fileId]);

  if (!fileId || isNaN(parseInt(fileId, 10))) {
    // TODO:
    return <Redirect to="/" />;
  }

  if (loading || !data || !data.file) {
    return <Spin />;
  }

  return (
    <div>
      <Breadcrumb level={data?.file?.level ?? 0} folders={folders} />

      <Row>
        <Col xs={24} md={6}>
          <div className="audio-player-container">
            {data.file.signedUrl && (
              <audio controls>
                <source src={data.file.signedUrl} type="audio/wav" />
                Your browser does not support the audio tag.
              </audio>
            )}
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div
            className="transcription-container"
            style={{ height: height ? height - 120 : 'auto' }}
          >
            {segments.map((segment) => (
              <div className="trans-line" key={segment.start_time}>
                <div className="trans-line-time">
                  [{formatTimestamp(segment.start_time, showHours)} -{' '}
                  {formatTimestamp(segment.end_time, showHours)}]
                </div>
                <div className="trans-line-content">{segment.transcript}</div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Transcription;
