import React from 'react';
import { Button } from 'antd';
import { FolderAddFilled, UploadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

interface Props {
  onNewFolder: () => void;
  onNewUpload: () => void;
}
const NewButton = (props: Props) => {
  const { onNewFolder, onNewUpload } = props;

  return (
    <div>
      <Button onClick={onNewUpload} style={{ marginLeft: 10 }} data-testid="btn-new-upload">
        <UploadOutlined />
        &nbsp; <FormattedMessage description="New upload menu text" defaultMessage="New upload" />
      </Button>
      <Button onClick={onNewFolder} style={{ marginLeft: 10 }} data-testid="btn-new-folder">
        <FolderAddFilled />
        &nbsp; <FormattedMessage description="New folder menu text" defaultMessage="New folder" />
      </Button>
    </div>
  );
};

export default NewButton;
