import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, memo } from 'react';
import { Row, Col, Spin } from 'antd';
import { Node, Range } from 'slate';
import { Slate, ReactEditor, Editable } from 'slate-react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import { useWindowSize } from '@/common/hook/window-size';

import { SoundContext, EditorContext, ViewManagerContext } from '../EditorContainer';

import CompleteCheckbox from './CompleteCheckbox';
import NoSpeech from './NoSpeech';
import NotePopup from './NotePopup';
import SegmentControl from './SegmentControl';
import SpeakerChooser from './SpeakerChooser';
import TimestampEditor from './TimestampEditor';

interface Props {
  value: Node[];
  onChange: (value: Node[]) => void;
  setEditing: (value: boolean) => void;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
}
const EditorCoreNew = (props: Props) => {
  const { value: _value, onChange, setEditing, contentRef } = props;

  const { height } = useWindowSize();

  const editor = useContext(EditorContext);
  const { setPos, pos, sound, audioReady, playing } = useContext(SoundContext);

  const [value, setValue] = useState<Node[]>(_value);
  const [lastValue, setLastValue] = useState<Node[]>([]);
  const [ready, setReady] = useState(false);

  const saveRef = useRef<any>(null);

  const currentSegmentId = useMemo(() => {
    return editor.getSegmentId(pos);
  }, [pos, editor]);

  const currentWordId = useMemo(() => {
    return editor.getWordId(pos);
  }, [pos, editor]);

  const handleWordClick = useCallback(
    (pos: number) => {
      if (sound) {
        if (sound.playing()) {
          sound.pause();
        }
        sound.seek(pos);
        setPos(pos);
      }
    },
    [sound, setPos]
  );

  const playOrPause = useCallback(() => {
    if (audioReady && sound) {
      if (playing) {
        sound.pause();
      } else {
        sound.play();
      }
    }
  }, [audioReady, playing, sound]);

  const handleChange = useCallback(
    (newValue: Node[]) => {
      setValue(newValue);
      if (saveRef.current) {
        window.clearTimeout(saveRef.current);
      }
      if (isEqual(newValue, lastValue)) {
        return;
      }
      setEditing(true);
      saveRef.current = window.setTimeout(() => {
        setEditing(false);
        setLastValue(newValue);
        onChange(newValue);
      }, 3000);
    },
    [setEditing, lastValue, onChange]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const { selection } = editor;
      if ('Tab' === e.key) {
        e.preventDefault();
        playOrPause();
      } else if (selection)
        if (!Range.isExpanded(selection) || e.getModifierState() || e.metaKey) {
          if (e.key.match(/(\?|!|\.)\s*$/) && Range.isCollapsed(selection)) {
            editor.handlePunctuation(e);
          } else {
            if ('Enter' !== e.key || e.ctrlKey) {
              if ('Backspace' === e.key) {
                editor.handleBackspace(e);
              } else if ('Delete' === e.key) {
                editor.handleDelete(e);
              }
            } else {
              e.preventDefault();
              if (e.shiftKey) {
                editor.insertSoftLineBreak();
              } else {
                editor.splitSegment();
              }
            }
          }
        } else {
          editor.handleExpandedSelection(e);
        }
    },
    [editor, playOrPause]
  );

  // Render callback for slate
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback(
    (props) => <Leaf {...props} onClick={handleWordClick} />,
    [handleWordClick]
  );

  // Auto scroll when playing
  useEffect(() => {
    if (currentSegmentId >= 0 && currentWordId >= 0 && contentRef.current) {
      const path = editor.getWordPoint(currentSegmentId, currentWordId);
      if (!path) {
        return;
      }
      const wordPoint = {
        path,
        offset: 0,
      };
      const wordNode = ReactEditor.toDOMPoint(editor, wordPoint);
      if (wordNode && wordNode[0] && wordNode[0].parentElement) {
        const wordRect = wordNode[0].parentElement.getBoundingClientRect();
        const scrollRect = contentRef.current.getBoundingClientRect();
        if (scrollRect.top <= wordRect.top && wordRect.bottom <= scrollRect.bottom) {
          return;
        }
        const segmentPoint = {
          path: [currentSegmentId],
          offset: 0,
        };
        const segmentNode = ReactEditor.toDOMPoint(editor, segmentPoint);
        if (segmentNode && segmentNode[0] && segmentNode[0].parentElement) {
          segmentNode[0].parentElement.scrollIntoView();
        }
      }
    }
  }, [contentRef, editor, currentSegmentId, currentWordId]);

  useEffect(() => {
    if (value.length === 0) {
      setValue(_value);
      setLastValue(_value);
      setReady(true);
    }
  }, [value, _value]);

  if (!ready) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height ? height - 300 : 'auto',
        }}
      >
        <Spin size="large" tip="Loading transcription ..." />
      </div>
    );
  }

  return (
    <>
      <style scoped>
        {`
          .teditor-segment[data-segment-id="${currentSegmentId}"] .teditor-segment-content {
            color: #521a00;
          }

          .teditor-segment[data-segment-id="${currentSegmentId}"] .teditor-word[data-word-id="${currentWordId}"] {
            color: #ea7d23 !important;
          }
        `}
      </style>

      <div
        className="editor-content-container"
        style={{ height: height ? height - 300 : 'auto' }}
        ref={contentRef}
      >
        <div className="teditor-root">
          {value.length > 0 ? (
            <Slate editor={editor} value={value} onChange={handleChange}>
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={handleKeyDown}
              />
            </Slate>
          ) : (
            <NoSpeech />
          )}
        </div>
      </div>
    </>
  );
};

const Element = memo(({ attributes, children, element }: any) => {
  const { start, end } = useContext(ViewManagerContext);

  switch (element.type) {
    case 'segment':
      if (start !== 0 || end !== 0) {
        if (start - 2 > element.id || element.id > end + 2) {
          return (
            <div className="teditor-segment" data-segment-id={element.id} {...attributes}>
              <Row>
                <Col xs={24} md={8} className="teditor-segment-left" contentEditable={false}>
                  <div>
                    <span className="teditor-segment-time">
                      <TimestampEditor element={element} />
                    </span>
                    <span className="teditor-segment-control">
                      <SegmentControl element={element} />
                    </span>
                  </div>
                  <hr />
                  <SpeakerChooser currentSpeaker={element.speaker_label} element={element} />
                </Col>
                <Col xs={24} md={12} className="teditor-segment-content">
                  <span className="teditor-word" contentEditable={false}>
                    {element.children.map((c) => c.text).join('')}
                  </span>
                </Col>
                <Col
                  xs={24}
                  md={4}
                  className={clsx({
                    'teditor-segment-right': true,
                    'segment-complete': element.complete,
                  })}
                  contentEditable={false}
                >
                  <CompleteCheckbox element={element} />
                  <NotePopup element={element} />
                </Col>
              </Row>
            </div>
          );
        }
      }
      return (
        <div className="teditor-segment" data-segment-id={element.id} {...attributes}>
          <Row>
            <Col xs={24} md={8} className="teditor-segment-left" contentEditable={false}>
              <div>
                <span className="teditor-segment-time">
                  <TimestampEditor element={element} />
                </span>
                <span className="teditor-segment-control">
                  <SegmentControl element={element} />
                </span>
              </div>
              <hr />
              <SpeakerChooser currentSpeaker={element.speaker_label} element={element} />
            </Col>
            <Col xs={24} md={12} className="teditor-segment-content">
              {children}
            </Col>
            <Col
              xs={24}
              md={4}
              className={clsx({
                'teditor-segment-right': true,
                'segment-complete': element.complete,
              })}
              contentEditable={false}
            >
              <CompleteCheckbox element={element} />
              <NotePopup element={element} />
            </Col>
          </Row>
        </div>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
});

const Leaf = memo(
  ({
    attributes,
    children,
    onClick,
    leaf: {
      id,
      start_time,
      end_time,
      confidenceClass,
      strikethrough,
      highlight,
      highlightBackground,
    },
  }: any) => {
    return (
      <span
        className={clsx({
          'teditor-word': true,
          [confidenceClass]: true,
          strikethrough: strikethrough,
          highlight: highlight,
        })}
        data-word-id={id}
        data-start={start_time}
        data-end={end_time}
        onClick={() => onClick(start_time)}
        style={highlight ? { backgroundColor: highlightBackground } : {}}
        {...attributes}
      >
        {children}
      </span>
    );
  }
);

export default memo(EditorCoreNew);
