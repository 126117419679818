import React from 'react';
import { Typography } from 'antd';
import { AudioMutedOutlined } from '@ant-design/icons';

import './NoSpeech.less';

const NoSpeech = () => {
  return (
    <div className="no-speech-root">
      <div>
        <Typography.Title level={1}>
          <AudioMutedOutlined />
        </Typography.Title>
        <Typography.Title level={3}>
          Oh no, we had a hard time detecting speech from the file you uploaded.
        </Typography.Title>
        <Typography.Paragraph>Here are some things to check:</Typography.Paragraph>

        <ol>
          <li>Doublecheck the uploaded file to ensure there's an audio track.</li>
          <li>Doublecheck to make sure the volume isn't too soft.</li>
          <li>Make sure you upload the right file.</li>
        </ol>

        <Typography.Paragraph>
          We're happy to help you figure this out. Send us an email at{' '}
          <a href="mailto:support@izitext.io">support@izitext.io</a>.
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default NoSpeech;
