import React, { useCallback, useMemo, useState, memo, useContext } from 'react';
import { Popover } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { SoundContext, FileContext, EditorContext } from '../EditorContainer';
import { transform, OutputSegment } from '../helper';

import AudioPlayer from './AudioPlayer';
import Toolbar from './Toolbar';
import ConfidenceStats from './ConfidenceStats';
import NoteList from './NoteList';
import SpeakerStats from './SpeakerStats';

interface Props {
  editing: boolean;
  savingDocument: boolean;
  showConfidence: boolean;
  setShowConfidence: (v: boolean) => void;
}
const EditorHeader = (props: Props) => {
  const { editing, savingDocument, showConfidence, setShowConfidence } = props;

  const file = useContext(FileContext);
  const editor = useContext(EditorContext);
  const { audioReady, pos, setPos, duration, playing, sound } = useContext(SoundContext);

  const [showQuality, setShowQuality] = useState(false);
  const [showSpeakers, setShowSpeakers] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  const originalSegments = useMemo(() => {
    if (file && file.transcription) {
      return transform(
        JSON.parse(file.transcription.originDocument.originalContent)
      ) as OutputSegment[];
    }
    return [] as OutputSegment[];
  }, [file]);

  const lastUpdate = useMemo(() => {
    if (file && file.transcription && file.transcription.originDocument) {
      return file.transcription.originDocument.updatedAt;
    }
    return null;
  }, [file]);

  const setPosition = useCallback(
    (pos: number) => {
      if (sound) {
        sound.seek(pos);
        setPos(pos);
      }
    },
    [sound, setPos]
  );

  const playOrPause = useCallback(() => {
    if (audioReady && sound) {
      if (playing) {
        sound.pause();
      } else {
        sound.play();
      }
    }
  }, [audioReady, playing, sound]);

  const strikethrough = useCallback(
    (e) => {
      e.preventDefault();
      editor.strikethrough();
    },
    [editor]
  );

  const highlight = useCallback(
    (e) => {
      e.preventDefault();
      editor.highlight();
    },
    [editor]
  );

  const undo = useCallback(() => {
    editor.undo();
  }, [editor]);

  const redo = useCallback(() => {
    editor.redo();
  }, [editor]);

  return (
    <>
      <div className="editor-header-container">
        <div className="editor-header-filename">
          <Link to={file.parent ? `/f/${file.parent.id}` : `/`} className="editor-header-back">
            <ArrowLeftOutlined />
          </Link>
          {file.name}
        </div>
      </div>
      <div className="editor-menu-container">
        <Popover
          content={
            <ConfidenceStats
              segments={originalSegments}
              show={showConfidence}
              setShow={setShowConfidence}
            />
          }
          trigger="click"
          visible={showQuality}
          onVisibleChange={setShowQuality}
          placement="bottomLeft"
        >
          <div className="editor-menu-item">Quality</div>
        </Popover>
        <Popover
          content={<SpeakerStats open={showSpeakers} />}
          trigger="click"
          visible={showSpeakers}
          onVisibleChange={setShowSpeakers}
          placement="bottomLeft"
        >
          <div className="editor-menu-item">Speakers</div>
        </Popover>
        <Popover
          content={<NoteList open={showNotes} seekTo={setPosition} />}
          trigger="click"
          visible={showNotes}
          onVisibleChange={setShowNotes}
          placement="bottomLeft"
        >
          <div className="editor-menu-item">Notes</div>
        </Popover>

        <div className="editor-header-last-update">
          {!editing &&
            (savingDocument
              ? 'Saving ...'
              : lastUpdate
              ? `Last saved at ${moment(lastUpdate).format('MMM DD, hh:mm a')}`
              : '')}
        </div>
      </div>
      <div className="editor-toolbar-container">
        <Toolbar
          playing={playing}
          playOrPause={playOrPause}
          strikethrough={strikethrough}
          highlight={highlight}
          savingDocument={savingDocument}
          undo={undo}
          redo={redo}
        />
      </div>
      <div className="editor-player-container">
        <AudioPlayer pos={pos} duration={duration} onSeek={setPosition} />
      </div>
    </>
  );
};

export default memo(EditorHeader);
