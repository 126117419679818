import React, { useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import useAntdMediaQuery from 'use-media-antd-query';

import MainMenu from './MainMenu';
import './index.less';

const SideMenu = () => {
  const screenSize = useAntdMediaQuery();

  const [collapsed, setCollapsed] = useState(false);

  const menuWidth = useMemo(() => {
    if (screenSize === 'sm' || screenSize === 'xs') {
      return 0;
    }
    return collapsed ? 48 : 200;
  }, [screenSize, collapsed]);

  return (
    <>
      <div
        style={{
          width: menuWidth,
          flex: `0 0 ${menuWidth}px`,
          maxWidth: menuWidth,
          minWidth: menuWidth,
          overflow: 'hidden',
        }}
      />

      <Layout.Sider
        style={{
          overflow: 'hidden',
          paddingTop: 48,
        }}
        trigger={null}
        className="fixed-sider"
        collapsible
        collapsed={collapsed}
        collapsedWidth={48}
        breakpoint="lg"
        onBreakpoint={(broken) => setCollapsed(broken)}
      >
        <div className="side-menu-main">
          <MainMenu collapsed={collapsed} />
        </div>
        <div className="side-menu-links">
          <Menu mode="inline" selectedKeys={[]} onClick={() => setCollapsed((c) => !c)}>
            <Menu.Item key="toggle" data-testid="btn-menu-toggle">
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Menu.Item>
          </Menu>
        </div>
      </Layout.Sider>
    </>
  );
};

export default SideMenu;
