import React from 'react';
import { Typography } from 'antd';

interface Props {
  plan: string;
}
const PlanDetails = (props: Props) => {
  const { plan } = props;

  return (
    <>
      <Typography.Title level={4}>Order summary</Typography.Title>
      <div className="row">
        <p>
          Subscription
          <br />
          <span style={{ fontSize: '90%' }}>
            {plan === 'annual' ? 'Billed annually' : 'Billed monthly'}
          </span>
        </p>
        <p>
          ${' '}
          {plan === 'annual'
            ? process.env.REACT_APP_ANNUAL_PRICE
            : process.env.REACT_APP_MONTHLY_PRICE}
        </p>
      </div>
      <div className="row">
        <p>Hour allowance</p>
        <p>
          {plan === 'annual'
            ? process.env.REACT_APP_ANNUAL_HOUR_ALLOWANCE
            : process.env.REACT_APP_MONTHLY_HOUR_ALLOWANCE}{' '}
          hours
        </p>
      </div>
      <div className="row">
        <p>Additional hour purchase</p>
        <p>$ {process.env.REACT_APP_PREMIUM_HOUR_PRICE} / hour</p>
      </div>
    </>
  );
};

export default PlanDetails;
